<template>
  <div>
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <!-- <div class="text-center">
          <p
            id="cancel-label"
            class="neonPink"
          >
            <img
              src="/logo/logo.png"
              alt="logo"
              width="100px"
            ><br>
            กรุณารอสักครู่...
          </p>
        </div> -->
        <div class="loader" />
      </template>
      <!-- <this-header /> -->

      <div class="container-new">
        <div class="d-flex align-items-center">
          <router-link
            v-if="UserData.usertype == 'member'"
            :to="{ name: 'tanghuay-home' }"
          >
            <button class="bnt-home">
              <i class="fas fa-home" />
            </button>
          </router-link>
          <div
            class="txt-right d-flex justify-content-between align-items-center rouded-b pb-25 px-50"
          >
            <div class="txt-right__text">
              <img
                src="/icons/wheel.png"
                alt="topic"
                width="30px"
              >
              <span style="color: black; font-size: 1.2rem">
                {{ NameShow }}</span>
            </div>

            <div class="d-flex align-items-center">
              <div
                class="txt-right__text"
                style="color: #646a6d"
              >
                <i class="far fa-clock mr-25" />
                <span>
                  {{ formatCloseDateName(CloseTimeShow ? CloseTimeShow : "-") }}
                  ชม.</span>
              </div>

              <span class="mx-25 text-black">|</span>

              <button
                style="
                  background-color: #2596f7;
                  color: #fff;
                  border-radius: 5px;
                  border: none;
                "
                @click="showRule"
              >
                กติกา
              </button>
            </div>
          </div>
        </div>

        <div v-if="!payOut">
          <!-- <div
            class="d-flex align-items-center justify-content-between"
            style="margin-top: 10px"
          >
            <router-link :to="{ name: 'tanghuay-round' }">
              <span class="text-black">
                <i class="far fa-arrow-left" /> ย้อนกลับ</span>
            </router-link>
            <h1 />
            <div class="">
              <b-button
                size="sm"
                style="height: 25px !important"
                @click="showRule()"
              >
                กติกา
              </b-button>
            </div>
          </div> -->
          <div class="flex">
            <div class="box-fixed">
              <div
                v-if="!items.length > 0"
                class="text-center text-num"
              >
                ยังไม่มีข้อมูล กรุณาใส่เลข ที่ต้องการแทง
              </div>

              <div
                v-else
                class="w-100 fixed-scroll"
                :class="{
                  'h-fix-1':
                    (!selectNumbet3U ||
                      !selectNumbet3T ||
                      !selectNumbet3D ||
                      !selectNumbet2U ||
                      !selectNumbet2T ||
                      !selectNumbet2D ||
                      !selectNumbet1U ||
                      !selectNumbet1D) &&
                    Mode === 'tap1',
                  'h-fix-2':
                    (selectNumbet3U || selectNumbet3T) && Mode === 'tap1',
                  'h-fix-3':
                    (selectNumbet2U || selectNumbet2D) && Mode === 'tap1',
                  'h-fix-4':
                    (selectNumbet1U || selectNumbet1D) && Mode === 'tap1',
                  'h-fix-5':
                    (!selectNumbet3U ||
                      !selectNumbet3T ||
                      !selectNumbet2U ||
                      !selectNumbet2D ||
                      !selectNumbet1U ||
                      !selectNumbet1D) &&
                    (Mode === 'tap2' || Mode === 'tap3'),
                  'h-fix-6':
                    (selectNumbet3U || selectNumbet3T) &&
                    (Mode === 'tap2' || Mode === 'tap3'),
                  'h-fix-7':
                    (selectNumbet2U || selectNumbet2D) &&
                    (Mode === 'tap2' || Mode === 'tap3'),
                  'h-fix-8':
                    (selectNumbet1U || selectNumbet1D) &&
                    (Mode === 'tap2' || Mode === 'tap3'),
                }"
              >
                <div class="text-length">
                  {{ items.length }} รายการ
                </div>
                <div
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <div class="box-type">
                    <div>
                      {{
                        index === 0 ||
                          item.num_type !== items[index - 1].num_type
                          ? item.num_type
                          : ""
                      }}
                    </div>
                  </div>

                  <div class="num-list_all">
                    <div class="num-result">
                      <div class="">
                        {{ item.num_lot }}
                      </div>
                    </div>

                    <i
                      class="far fa-trash-alt"
                      style="color: #a6a6a6"
                      @click="
                        RemovePush(items, item.num_lot, item.num_type),
                        DisableInList(
                          item.bet_digit,
                          item.bet_type,
                          item.num_lot
                        )
                      "
                    />
                  </div>
                </div>

                <button
                  class="btn-deler"
                  @click="Undo()"
                >
                  ล้างโพย
                </button>
              </div>
            </div>
            <div class="box-flex">
              <div
                class="fixed-scroll text-dark"
                style="margin-top: 10px"
              >
                <div class="row m-0">
                  <div
                    class="box-lt col-6 pl-0 pr-0 cursor-pointer"
                    :class="{ 'box-lt__active': Mode === 'tap1' }"
                    style="border-right: 1px solid #eaecf3"
                    @click="
                      (Mode = 'tap1'),
                      (selectNumbet2U = false),
                      (selectNumbet2D = false),
                      (selectNumbet2T = false)
                    "
                  >
                    <div class="text-center">
                      เลือกจากแผง
                    </div>
                  </div>

                  <div
                    class="box-lt col-6 pl-0 pr-0 cursor-pointer"
                    :class="{ 'box-lt__active': Mode === 'tap2' }"
                    @click="Mode = 'tap2'"
                  >
                    <div class="text-center">
                      กดตัวเลข
                    </div>
                  </div>

                  <div
                    class="box-lt col-6 pl-0 pr-0 cursor-pointer"
                    :class="{ 'box-lt__active': Mode === 'tap3' }"
                    style="border-right: 1px solid #eaecf3"
                    @click="Mode = 'tap3',TogType('up',2),selectNumbet2T = true"
                  >
                    <div class="text-center">
                      จับวิน
                    </div>
                  </div>

                  <div
                    class="box-lt col-6 pl-0 pr-0 cursor-pointer"
                    :class="{ 'box-lt__active': Mode === 'tap4' }"
                    @click="Mode = 'tap4',TogType('all',0),selectNumbet3U2U2DFlip = true"
                  >
                    <div class="text-center">
                      ทำนายฝัน
                    </div>
                  </div>
                </div>

                <div
                  v-if="Mode !== 'tap3'"
                  class="app__lotto-slug slug-classic pp"
                  style="margin-top: 3px"
                >
                  <div
                    class="slug-item cursor-pointer"
                    :class="{ activeder: selectNumbet3U }"
                    @click="TogType('up', 3)"
                  >
                    <span class="label">3 ตัวบน</span>
                    <span class="rate">{{ RateAll.Three.Up.Rate }}</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer"
                    :class="{ activeder: selectNumbet3T }"
                    @click="TogType('tot', 3)"
                  >
                    <span class="label">3 ตัวโต๊ด</span>
                    <span class="rate">{{ RateAll.Three.Tot.Rate }}</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer"
                    :class="{ activeder: selectNumbet3D }"
                    @click="TogType('flip', 3)"
                  >
                    <span class="label">3 ตัวกลับ</span>
                    <span class="rate">ตัวเลือก</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer"
                    :class="{ activeder: selectNumbet2U }"
                    @click="TogType('up', 2)"
                  >
                    <span class="label">2 ตัวบน</span>
                    <span class="rate">{{ RateAll.Two.Up.Rate }}</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer"
                    :class="{ activeder: selectNumbet2D }"
                    @click="TogType('down', 2)"
                  >
                    <span class="label">2 ตัวล่าง</span>
                    <span class="rate">{{ RateAll.Two.Down.Rate }}</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer"
                    :class="{ activeder: selectNumbet2T }"
                    @click="TogType('flip', 2)"
                  >
                    <span class="label">2 ตัวกลับ</span>
                    <span class="rate">ตัวเลือก</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer slug-item-lg"
                    :class="{ activeder: selectNumbet1U }"
                    @click="TogType('up', 1)"
                  >
                    <span class="label">วิ่งบน</span>
                    <span class="rate">{{ RateAll.One.Up.Rate }}</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer slug-item-lg"
                    :class="{ activeder: selectNumbet1D }"
                    @click="TogType('down', 1)"
                  >
                    <span class="label">วิ่งล่าง</span>
                    <span class="rate">{{ RateAll.One.Down.Rate }}</span>
                  </div>
                </div>
                <div>
                  <div v-if="Mode === 'tap1'">
                    <hr
                      style="border-top: 1px solid #1e1e1e"
                      class="mt-1 mb-1"
                    >
                    <!-- หวย 3 ตัว -->
                    <div
                      v-if="selectNumbet3U || selectNumbet3T"
                      class="grid-container"
                    >
                      <div
                        class="grid-item"
                        :class="{ actived: SubThree0 }"
                        @click="TogThreeType(0, 3)"
                      >
                        000
                      </div>
                      <div
                        class="grid-item"
                        :class="{ actived: SubThree1 }"
                        @click="TogThreeType(100, 3)"
                      >
                        100
                      </div>
                      <div
                        class="grid-item"
                        :class="{ actived: SubThree2 }"
                        @click="TogThreeType(200, 3)"
                      >
                        200
                      </div>
                      <div
                        class="grid-item"
                        :class="{ actived: SubThree3 }"
                        @click="TogThreeType(300, 3)"
                      >
                        300
                      </div>
                      <div
                        class="grid-item"
                        :class="{ actived: SubThree4 }"
                        @click="TogThreeType(400, 3)"
                      >
                        400
                      </div>
                      <div
                        class="grid-item"
                        :class="{ actived: SubThree5 }"
                        @click="TogThreeType(500, 3)"
                      >
                        500
                      </div>
                      <div
                        class="grid-item"
                        :class="{ actived: SubThree6 }"
                        @click="TogThreeType(600, 3)"
                      >
                        600
                      </div>
                      <div
                        class="grid-item"
                        :class="{ actived: SubThree7 }"
                        @click="TogThreeType(700, 3)"
                      >
                        700
                      </div>
                      <div
                        class="grid-item"
                        :class="{ actived: SubThree8 }"
                        @click="TogThreeType(800, 3)"
                      >
                        800
                      </div>
                      <div
                        class="grid-item"
                        :class="{ actived: SubThree9 }"
                        @click="TogThreeType(900, 3)"
                      >
                        900
                      </div>
                    </div>

                    <!-- หัว 2 ตัว -->
                    <div
                      v-if="selectNumbet2U || selectNumbet2D"
                      class="row pp m-0"
                    >
                      <div class="col-12 col-lg-4 text-dark p-0">
                        <strong>19 ประตู</strong>
                        <ul class="bet-quick">
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D0 = CheckTog(
                                'nine',
                                PickTwoArr,
                                0
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D0.SwipChang,
                                PickTwoArr,
                                NineDoor.D0.status
                              )
                            "
                          >
                            0
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D1 = CheckTog(
                                'nine',
                                PickTwoArr,
                                1
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D1.SwipChang,
                                PickTwoArr,
                                NineDoor.D1.status
                              )
                            "
                          >
                            1
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D2 = CheckTog(
                                'nine',
                                PickTwoArr,
                                2
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D2.SwipChang,
                                PickTwoArr,
                                NineDoor.D2.status
                              )
                            "
                          >
                            2
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D3 = CheckTog(
                                'nine',
                                PickTwoArr,
                                3
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D3.SwipChang,
                                PickTwoArr,
                                NineDoor.D3.status
                              )
                            "
                          >
                            3
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D4 = CheckTog(
                                'nine',
                                PickTwoArr,
                                4
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D4.SwipChang,
                                PickTwoArr,
                                NineDoor.D4.status
                              )
                            "
                          >
                            4
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D5 = CheckTog(
                                'nine',
                                PickTwoArr,
                                5
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D5.SwipChang,
                                PickTwoArr,
                                NineDoor.D5.status
                              )
                            "
                          >
                            5
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D6 = CheckTog(
                                'nine',
                                PickTwoArr,
                                6
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D6.SwipChang,
                                PickTwoArr,
                                NineDoor.D6.status
                              )
                            "
                          >
                            6
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D7 = CheckTog(
                                'nine',
                                PickTwoArr,
                                7
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D7.SwipChang,
                                PickTwoArr,
                                NineDoor.D7.status
                              )
                            "
                          >
                            7
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D8 = CheckTog(
                                'nine',
                                PickTwoArr,
                                8
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D8.SwipChang,
                                PickTwoArr,
                                NineDoor.D8.status
                              )
                            "
                          >
                            8
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D9 = CheckTog(
                                'nine',
                                PickTwoArr,
                                9
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D9.SwipChang,
                                PickTwoArr,
                                NineDoor.D9.status
                              )
                            "
                          >
                            9
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-lg-4 text-dark p-0">
                        <strong>รูดหน้า</strong>
                        <ul class="bet-quick">
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D0 = CheckTog(
                                'front',
                                PickTwoArr,
                                0
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D0.SwipChang,
                                PickTwoArr,
                                RootFront.D0.status
                              )
                            "
                          >
                            0
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D1 = CheckTog(
                                'front',
                                PickTwoArr,
                                1
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D1.SwipChang,
                                PickTwoArr,
                                RootFront.D1.status
                              )
                            "
                          >
                            1
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D2 = CheckTog(
                                'front',
                                PickTwoArr,
                                2
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D2.SwipChang,
                                PickTwoArr,
                                RootFront.D2.status
                              )
                            "
                          >
                            2
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D3 = CheckTog(
                                'front',
                                PickTwoArr,
                                3
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D3.SwipChang,
                                PickTwoArr,
                                RootFront.D3.status
                              )
                            "
                          >
                            3
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D4 = CheckTog(
                                'front',
                                PickTwoArr,
                                4
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D4.SwipChang,
                                PickTwoArr,
                                RootFront.D4.status
                              )
                            "
                          >
                            4
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D5 = CheckTog(
                                'front',
                                PickTwoArr,
                                5
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D5.SwipChang,
                                PickTwoArr,
                                RootFront.D5.status
                              )
                            "
                          >
                            5
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D6 = CheckTog(
                                'front',
                                PickTwoArr,
                                6
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D6.SwipChang,
                                PickTwoArr,
                                RootFront.D6.status
                              )
                            "
                          >
                            6
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D7 = CheckTog(
                                'front',
                                PickTwoArr,
                                7
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D7.SwipChang,
                                PickTwoArr,
                                RootFront.D7.status
                              )
                            "
                          >
                            7
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D8 = CheckTog(
                                'front',
                                PickTwoArr,
                                8
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D8.SwipChang,
                                PickTwoArr,
                                RootFront.D8.status
                              )
                            "
                          >
                            8
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D9 = CheckTog(
                                'front',
                                PickTwoArr,
                                9
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D9.SwipChang,
                                PickTwoArr,
                                RootFront.D9.status
                              )
                            "
                          >
                            9
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-xl-4 text-dark p-0">
                        <strong>รูดหลัง</strong>
                        <ul class="bet-quick">
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D0 = CheckTog(
                                'back',
                                PickTwoArr,
                                0
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D0.SwipChang,
                                PickTwoArr,
                                RootBack.D0.status
                              )
                            "
                          >
                            0
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D1 = CheckTog(
                                'back',
                                PickTwoArr,
                                1
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D1.SwipChang,
                                PickTwoArr,
                                RootBack.D1.status
                              )
                            "
                          >
                            1
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D2 = CheckTog(
                                'back',
                                PickTwoArr,
                                2
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D2.SwipChang,
                                PickTwoArr,
                                RootBack.D2.status
                              )
                            "
                          >
                            2
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D3 = CheckTog(
                                'back',
                                PickTwoArr,
                                3
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D3.SwipChang,
                                PickTwoArr,
                                RootBack.D3.status
                              )
                            "
                          >
                            3
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D4 = CheckTog(
                                'back',
                                PickTwoArr,
                                4
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D4.SwipChang,
                                PickTwoArr,
                                RootBack.D4.status
                              )
                            "
                          >
                            4
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D5 = CheckTog(
                                'back',
                                PickTwoArr,
                                5
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D5.SwipChang,
                                PickTwoArr,
                                RootBack.D5.status
                              )
                            "
                          >
                            5
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D6 = CheckTog(
                                'back',
                                PickTwoArr,
                                6
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D6.SwipChang,
                                PickTwoArr,
                                RootBack.D6.status
                              )
                            "
                          >
                            6
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D7 = CheckTog(
                                'back',
                                PickTwoArr,
                                7
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D7.SwipChang,
                                PickTwoArr,
                                RootBack.D7.status
                              )
                            "
                          >
                            7
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D8 = CheckTog(
                                'back',
                                PickTwoArr,
                                8
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D8.SwipChang,
                                PickTwoArr,
                                RootBack.D8.status
                              )
                            "
                          >
                            8
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D9 = CheckTog(
                                'back',
                                PickTwoArr,
                                9
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D9.SwipChang,
                                PickTwoArr,
                                RootBack.D9.status
                              )
                            "
                          >
                            9
                          </li>
                        </ul>
                      </div>
                    </div>

                    <!-- เลขวิ่ง -->
                    <div
                      v-if="selectNumbet1D || selectNumbet1U"
                      class="grid-container"
                    >
                      <div
                        v-for="item in PickOneArr"
                        :key="item.Num"
                        class="grid-item"
                        :class="{ actived: ActiveBet(item) }"
                        @click="PickNum(item, PickOneArr)"
                      >
                        {{ item.Num }}
                      </div>
                    </div>

                    <!-- เลือกจากแผง -->
                    <ul
                      v-if="selectNumbet3U || selectNumbet3T"
                      class="bet-number pp"
                    >
                      <li
                        v-for="item in 100"
                        :key="item.num"
                        :class="{
                          active: ActiveBet(PickThreeArr[item - 1 + PickThree]),
                        }"
                        class="number-item2"
                        @click="
                          PickNum(
                            PickThreeArr[item - 1 + PickThree],
                            PickThreeArr
                          )
                        "
                      >
                        {{ PickThreeArr[item - 1 + PickThree].Num }}
                      </li>
                    </ul>
                    <ul
                      v-if="selectNumbet2U || selectNumbet2D"
                      class="bet-number pp"
                    >
                      <li
                        v-for="item in PickTwoArr"
                        :key="item.Num"
                        :class="{ active: ActiveBet(item) }"
                        class="number-item2"
                        @click="PickNum(item, PickTwoArr)"
                      >
                        {{ item.Num }}
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- <div class="padding-bottom-10" /> -->
                <!-- สิ้นสุดเลือกหวยจากแผง -->

                <!-- เลือกหวยกดเลข -->
                <div
                  v-if="
                    Mode === 'tap2' &&
                      (selectNumbet3U ||
                        selectNumbet3T ||
                        selectNumbet2U ||
                        selectNumbet2D ||
                        selectNumbet1D ||
                        selectNumbet1U)
                  "
                >
                  <ul
                    v-if="selectNumbet2U || selectNumbet2D"
                    class="bet-group pp"
                  >
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.NineDoor }"
                      @click="
                        (TwoPushSubType.NineDoor = !TwoPushSubType.NineDoor),
                        (TwoPushSubType.RootFront = false),
                        (TwoPushSubType.RootBack = false)
                      "
                    >
                      <span class="label">19 ประตู</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.RootFront }"
                      @click="
                        (TwoPushSubType.RootFront = !TwoPushSubType.RootFront),
                        (TwoPushSubType.NineDoor = false),
                        (TwoPushSubType.RootBack = false)
                      "
                    >
                      <span class="label">รูดหน้า</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.RootBack }"
                      @click="
                        (TwoPushSubType.RootBack = !TwoPushSubType.RootBack),
                        (TwoPushSubType.NineDoor = false),
                        (TwoPushSubType.RootFront = false)
                      "
                    >
                      <span class="label">รูดหลัง</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.Ble }"
                      @click="SelctTwoTool('เลขเบิ้ล')"
                    >
                      <span class="label">เลขเบิ้ล</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.TwoHight }"
                      @click="SelctTwoTool('สองตัวสูง')"
                    >
                      <span class="label">สองตัวสูง</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.TwoLow }"
                      @click="SelctTwoTool('สองตัวต่ำ')"
                    >
                      <span class="label">สองตัวต่ำ</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.TwoEven }"
                      @click="SelctTwoTool('สองตัวคู่')"
                    >
                      <span class="label">สองตัวคู่</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.TwoOdd }"
                      @click="SelctTwoTool('สองตัวคี่')"
                    >
                      <span class="label">สองตัวคี่</span>
                    </li>
                  </ul>
                  <hr
                    style="border-top: 0.5px solid #1e1e1e"
                    class="mt-1 mb-1"
                  >
                  <div style="width: 98% !important">
                    <h6
                      v-if="!CheckBetType"
                      class="text-center text-pink"
                    >
                      ระบุตัวเลข {{ CalMaxLength() }} ตัว
                    </h6>
                    <h6
                      v-else
                      class="text-center text-pink-1"
                    >
                      กรุณาเลือกประเภท
                    </h6>
                    <!-- {{ CheckBetType }} -->
                    <div class="text-center mt-1">
                      <otp-input
                        v-if="BugOtp === true"
                        v-model="NumBet"
                        class="style-1"
                        :length="CalMaxLength()"
                        pattern="[^0-9]+"
                        :ignore-pattern="false"
                        :size="24"
                        :disabled="CheckBetType"
                        @valid="handleOnComplete()"
                      />
                    </div>
                    <div class="row m-0 w-100">
                      <div class="col-4 mmb-1">
                        <button
                          data-id="1"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(1)"
                        >
                          1
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="2"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(2)"
                        >
                          2
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="3"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(3)"
                        >
                          3
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="4"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(4)"
                        >
                          4
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="5"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(5)"
                        >
                          5
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="6"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(6)"
                        >
                          6
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="7"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(7)"
                        >
                          7
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="8"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(8)"
                        >
                          8
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="9"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(9)"
                        >
                          9
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <!-- @click="NumBet = ''" -->
                        <button
                          v-b-modal.modal-1
                          class="button-num-cals"
                          @click="Undo()"
                        >
                          ล้างโพย
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(0)"
                        >
                          0
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="delete"
                          class="button-num-calss"
                          @click="NumBet = NumBet.slice(0, -1)"
                        >
                          <i
                            aria-hidden="true"
                            class="fas fa-backspace"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    !(
                      selectNumbet3U ||
                      selectNumbet3T ||
                      selectNumbet2U ||
                      selectNumbet2D ||
                      selectNumbet1D ||
                      selectNumbet1U
                    ) &&
                      Mode !== 'tap3' &&
                      Mode !== 'tap4'
                  "
                  class="card__loading"
                >
                  <i
                    class="icon fas fa-mouse-pointer text-pink-1"
                    size="10"
                  />
                  <span class="label mb-2 text-pink-1">โปรดเลือกประเภท</span>
                </div>

                <div
                  v-if="Mode === 'tap3'"
                  class="mt-1"
                >
                  <hr style="margin: .4rem 0 !important;">
                  <div class="app__lotto-slug slug-classic pp">
                    <div
                      class="slug-item2 cursor-pointer"
                      :class="{ 'activeder': winActive === 'win2' }"
                      @click="winTap('win2'),selectNumbet2U = true,selectNumbet2T = true"
                    >
                      <span class="label">สองตัว</span>
                    </div>
                    <div
                      class="slug-item2 cursor-pointer"
                      :class="{ 'activeder': winActive === 'win3' }"
                      @click="winTap('win3'),selectNumbet3U = true,selectNumbet3D = true"
                    >
                      <span class="label">สามตัว</span>
                    </div>
                  </div>

                  <div style="padding: 2.5px 7px 5px 4px;">
                    <div
                      class="slug-item-s cursor-pointer"
                      :class="{ 'actives': winActive === 'win3t' }"
                      @click="winTap('win3t'),selectNumbet3U = true,selectNumbet3D = true"
                    >
                      <span class="label">สามตัวจับสองหาง</span>
                    </div>
                  </div>

                  <div
                    class="app__lotto-slug slug-classic pp"
                    style="margin-top: 3px"
                  >
                    <template v-if="winActive === 'win3' || winActive === 'win3t'">
                      <div
                        class="slug-item cursor-pointer"
                        :class="{ activeder: selectNumbet3U }"
                        @click="TogType('up', 3)"
                      >
                        <span class="label">3 ตัวบน</span>
                        <span class="rate">{{ RateAll.Three.Up.Rate }}</span>
                      </div>
                      <div
                        class="slug-item cursor-pointer"
                        :class="{ activeder: selectNumbet3T }"
                        @click="TogType('tot', 3)"
                      >
                        <span class="label">3 ตัวโต๊ด</span>
                        <span class="rate">{{ RateAll.Three.Tot.Rate }}</span>
                      </div>
                      <div
                        class="slug-item cursor-pointer"
                        :class="{ activeder: selectNumbet3D }"
                        @click="TogType('flip', 3)"
                      >
                        <span class="label">3 ตัวกลับ</span>
                        <span class="rate">ตัวเลือก</span>
                      </div>
                    </template>

                    <template v-else>
                      <div
                        class="slug-item cursor-pointer"
                        :class="{ activeder: selectNumbet2U }"
                        @click="TogType('up', 2)"
                      >
                        <span class="label">2 ตัวบน</span>
                        <span class="rate">{{ RateAll.Two.Up.Rate }}</span>
                      </div>
                      <div
                        class="slug-item cursor-pointer"
                        :class="{ activeder: selectNumbet2D }"
                        @click="TogType('down', 2)"
                      >
                        <span class="label">2 ตัวล่าง</span>
                        <span class="rate">{{ RateAll.Two.Down.Rate }}</span>
                      </div>
                      <div
                        class="slug-item cursor-pointer"
                        :class="{ activeder: selectNumbet2T }"
                        @click="TogType('flip', 2)"
                      >
                        <span class="label">2 ตัวกลับ</span>
                        <span class="rate">ตัวเลือก</span>
                      </div>
                    </template>
                  </div>

                  <div class="box-ext mt-75">
                    <p class="text-ext m-0">
                      ตัวเลือกเพิ่มเติม
                    </p>

                    <div class="p-25">
                      <div
                        class="d-flex flex-wrap mt-50 ext-btn"
                        style="gap: 0.5rem"
                      >
                        <template v-if="winActive === 'win3'">
                          <button
                            :class="`${butWinTong ? 'act':''}`"
                            @click="butWinTong = !butWinTong"
                          >
                            เลขตอง
                          </button>
                        </template>

                        <template v-if="winActive === 'win2'">
                          <button
                            :class="`${butWinBle ? 'act':''}`"
                            @click="butWinBle = !butWinBle"
                          >เลขเบิ้ล</button>
                        </template>

                        <template v-if="winActive === 'win3t'">
                          <button
                            :class="`${butWin3tTong ? 'act':''}`"
                            @click="butWin3tTong = !butWin3tTong"
                          >เลขตอง</button>
                          <button
                            :class="`${butWin3tFrontDup ? 'act':''}`"
                            @click="butWin3tFrontDup = !butWin3tFrontDup"
                          >ไม่เบิ้ลหน้า</button>
                          <button
                            :class="`${butWin3tEndDup ? 'act':''}`"
                            @click="butWin3tEndDup = !butWin3tEndDup"
                          >ไม่เบิ้ลท้าย</button>
                          <button
                            :class="`${butWin3tNoSpread ? 'act':''}`"
                            @click="butWin3tNoSpread = !butWin3tNoSpread"
                          >ไม่เอาเลขถ่าง</button>
                        </template>
                      </div>
                    </div>
                  </div>
                  <hr style="margin: .4rem 0 !important;">
                  <div class="row mt-50 p-0 ml-0 mr-0">
                    <div
                      v-for="item in number_win"
                      :key="item"
                      class="col-3 p-0"
                    >
                      <div class="button-show">
                        {{ item }}
                      </div>
                    </div>
                  </div>
                  <hr style="margin: .4rem 0 !important;">
                  <div class="row w-100 mt-50 ml-0 mr-0">
                    <div
                      v-for="(item, index) in dialPad"
                      :key="index"
                      class="col-4 mmb-1"
                    >
                      <button
                        data-id="1"
                        class="button-num-cal push-btn-effect"
                        :class="[
                          item === 'ลบ' || item === 'ล้างโพย'
                            ? 'button-num-calss'
                            : 'bg-[#FFF2CC]',
                        ]"
                        @click="Betting2(item)"
                      >
                        <span v-if="item !== 'ลบ'">{{ item }}</span>

                        <i
                          v-else
                          aria-hidden="true"
                          class="fas fa-backspace"
                        />
                      </button>
                    </div>
                  </div>

                  <button
                    class="btn-rand"
                    @click="genPairs()"
                  >
                    จับวิน
                  </button>
                </div>

                <div
                  v-if="Mode === 'tap4'"
                  class="mt-1"
                >
                  <div
                    class="app__lotto-slug slug-classic pp"
                    style="margin-top: 3px"
                  >
                    <div
                      class="slug-item cursor-pointer slug-item-lg"
                      :class="{ activeder: selectNumbet3U2U2D }"
                      @click="TogType('all', 0)"
                    >
                      <span class="label">3ตัวบน,2ตัวบน,2ตัวล่าง</span>
                      <span class="rate">ตัวเลือก</span>
                    </div>
                    <div
                      class="slug-item cursor-pointer slug-item-lg"
                      :class="{ activeder: selectNumbet3U2U2DFlip }"
                      @click="TogType('flip', 0)"
                    >
                      <span class="label">กลับเลข</span>
                      <span class="rate">ตัวเลือก</span>
                    </div>
                  </div>
                  <div class="mb-50">
                    <input
                      v-model="search_type"
                      type="text"
                      class="input-search-dream"
                      placeholder="พิมพ์ความฝันของท่านที่นี่"
                      @input="clearSelectedType"
                    >
                  </div>

                  <div
                    class="d-flex flex-wrap justify-content-center"
                    style="gap: .25rem"
                  >
                    <button
                      v-for="(item, index) in thaiList"
                      :key="index"
                      class="btn-dream"
                      :class="{ dream_active: selectedType === item }"
                      @click="getMydream(item)"
                    >{{ item }}</button>
                  </div>

                  <div
                    v-for="(item, index) in filteredDreams"
                    :key="index"
                  >
                    <div
                      class="card-dream"
                      @click="genDreams(item)"
                    >
                      <div class="d-flex align-items-center">
                        <img
                          :src="`https://api.hapi-lot.com/api/dreams/img/${item.image_path}`"
                          alt="img"
                          height="38"
                        >
                        <span class="text-tie ml-25">{{ item.name }}</span>
                      </div>

                      <div class="d-flex align-items-center mt-50">
                        <div class="dream-n1 mr-50">
                          เลขเด่น : <b>{{ item.one_num[0] }}</b>
                        </div>

                        <div class="dream-n1">
                          เลขเด่น : <b>{{ item.one_num[1] }}</b>
                        </div>
                      </div>

                      <div class="d-flex align-items-center mt-50">
                        <div
                          class="lek-num-chok-txt"
                        >
                          เลขนำโชค
                        </div>

                        <span style="font-size: 13px;"> : {{ combinedNumbers(item.two_num, item.three_num) }}</span>
                      </div>

                      <div
                        class="d-flex flex-wrap mt-1"
                        style="gap: 3px;"
                      >
                        <div
                          v-for="(i, index) in item.tags"
                          :key="index"
                          class="txt-tag"
                        >
                          {{ i }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <b-col
            col="12"
            sm="12"
            lg="12"
            md="12"
          >

          </b-col> -->
          <div
            v-if="
              selectNumbet3U ||
                selectNumbet3T ||
                selectNumbet2U ||
                selectNumbet2D
            "
            class="paddinsg-bottom-10"
          />
        </div>

        <!-- <b-col
          v-if="!payOut"
          lg="12"
          md="12"
          order="2"
          order-lg="6"
        >
          <div class="card-infox justify-content-center">
            <div>
              <h5 class="font-medium-1 font-weight-bolder m-0 text-pink">
                เงื่อนไขการแทง
              </h5>
            </div>

            <div class="d-flex justify-content-center flex-wrap">
              <div class="card-lotto py-50 px-1">
                <tag> จ่าย {{ RateAll.Three.Up.Rate }} </tag>
                <h4>3 ตัวบน</h4>
                <p>
                  แทงขั้นต่ำต่อครั้ง/เลข : {{ Commas(RateAll.Three.Up.Min) }}
                </p>
                <p>
                  แทงสูงสุดต่อครั้ง/เลข : {{ Commas(RateAll.Three.Up.Max) }}
                </p>
              </div>

              <div class="card-lotto py-50 px-1">
                <tag> จ่าย {{ RateAll.Three.Tot.Rate }} </tag>
                <h4>3 ตัวโต๊ด</h4>
                <p>
                  แทงขั้นต่ำต่อครั้ง/เลข : {{ Commas(RateAll.Three.Tot.Min) }}
                </p>
                <p>
                  แทงสูงสุดต่อครั้ง/เลข : {{ Commas(RateAll.Three.Tot.Max) }}
                </p>
              </div>

              <div class="card-lotto py-50 px-1">
                <tag> จ่าย {{ RateAll.Two.Up.Rate }} </tag>
                <h4>2 ตัวบน</h4>
                <p>แทงขั้นต่ำต่อครั้ง/เลข : {{ Commas(RateAll.Two.Up.Min) }}</p>
                <p>แทงสูงสุดต่อครั้ง/เลข : {{ Commas(RateAll.Two.Up.Max) }}</p>
              </div>

              <div class="card-lotto py-50 px-1">
                <tag> จ่าย {{ RateAll.Two.Down.Rate }} </tag>
                <h4>2 ตัวล่าง</h4>
                <p>
                  แทงขั้นต่ำต่อครั้ง/เลข : {{ Commas(RateAll.Two.Down.Min) }}
                </p>
                <p>
                  แทงสูงสุดต่อครั้ง/เลข : {{ Commas(RateAll.Two.Down.Max) }}
                </p>
              </div>

              <div class="card-lotto py-50 px-1">
                <tag> จ่าย {{ RateAll.One.Up.Rate }} </tag>
                <h4>วิ่งบน</h4>
                <p>แทงขั้นต่ำต่อครั้ง/เลข : {{ Commas(RateAll.One.Up.Min) }}</p>
                <p>แทงสูงสุดต่อครั้ง/เลข : {{ Commas(RateAll.One.Up.Max) }}</p>
              </div>

              <div class="card-lotto py-50 px-1">
                <tag> จ่าย {{ RateAll.One.Down.Rate }} </tag>
                <h4>วิ่งล่าง</h4>
                <p>
                  แทงขั้นต่ำต่อครั้ง/เลข : {{ Commas(RateAll.One.Down.Min) }}
                </p>
                <p>
                  แทงสูงสุดต่อครั้ง/เลข : {{ Commas(RateAll.One.Down.Max) }}
                </p>
              </div>
            </div>

          </div>
        </b-col> -->

        <b-col
          v-if="!payOut"
          lg="12"
          md="12"
          order="2"
          order-lg="6"
        >
          <div class="card-infox block mt-1">
            <b-row>
              <b-col
                md="6"
                cols="12"
                class="p-0"
              >
                <div class="txt_head">
                  <h5
                    id="num_check"
                    class="text-pink"
                  >
                    หมายเลขจ่ายตาม %
                  </h5>
                </div>
                <div class="mb-1 text-pink">
                  <div class="condition">
                    <div v-if="Title.LimitU3.length > 0">
                      3 ตัวบน <br>
                      <b-badge
                        v-for="item in Title.LimitU3"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitD3.length > 0">
                      3 ตัวล่าง <br>
                      <b-badge
                        v-for="item in Title.LimitD3"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitT3.length > 0">
                      3 โต๊ด <br>
                      <b-badge
                        v-for="item in Title.LimitT3"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitU2.length > 0">
                      2 ตัวบน <br>
                      <b-badge
                        v-for="item in Title.LimitU2"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitD2.length > 0">
                      2 ตัวล่าง <br>
                      <b-badge
                        v-for="item in Title.LimitD2"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitT2.length > 0">
                      2 ตัวโต๊ด(บน) <br>
                      <b-badge
                        v-for="item in Title.LimitT2"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitU1.length > 0">
                      วิ่งบน <br>
                      <b-badge
                        v-for="item in Title.LimitU1"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitD1.length > 0">
                      วิ่งล่าง <br>
                      <b-badge
                        v-for="item in Title.LimitD1"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                    </div>
                  </div>
                </div>

                <!-- แสดงผล mobile -->
                <hr class="d-block d-md-none">
              </b-col>

              <b-col
                md="6"
                cols="12"
                class="text-black p-0"
              >
                <div class="txt_head">
                  <h5 class="text-pink">
                    หมายเลขปิดรับ
                  </h5>
                </div>
                <div class="mb-1">
                  <div class="condition">
                    <div v-if="CloseBet.CloseU3.length > 0">
                      3 ตัวบน <br>
                      <b-badge
                        v-for="item in CloseBet.CloseU3"
                        :key="`U3${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseD3.length > 0">
                      3 ตัวล่าง <br>
                      <b-badge
                        v-for="item in CloseBet.CloseD3"
                        :key="`D3${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseT3.length > 0">
                      3 โต๊ด <br>
                      <b-badge
                        v-for="item in CloseBet.CloseT3"
                        :key="`T3${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseU2.length > 0">
                      2 ตัวบน <br>
                      <b-badge
                        v-for="item in CloseBet.CloseU2"
                        :key="`U2${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseD2.length > 0">
                      2 ตัวล่าง <br>
                      <b-badge
                        v-for="item in CloseBet.CloseD2"
                        :key="`D2${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseT2.length > 0">
                      2 ตัวโต๊ด(บน) <br>
                      <b-badge
                        v-for="item in CloseBet.CloseT2"
                        :key="`T2${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseU1.length > 0">
                      วิ่งบน <br>
                      <b-badge
                        v-for="item in CloseBet.CloseU1"
                        :key="`U1${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseD1.length > 0">
                      วิ่งล่าง<br>
                      <b-badge
                        v-for="item in CloseBet.CloseD1"
                        :key="`D1${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>

        <div v-if="payOut">
          <div class="text-dark">
            <div class="nav-text pd-h-20 text-center pt-1 pb-1 font-medium-1">
              รายการแทงทั้งหมด {{ items.length }} รายการ
            </div>

            <div
              v-if="items.filter((ele) => ele.num_type == '3ตัวบน').length > 0"
              class="px-1 pb-1"
            >
              <div class="box-nx">
                <div class="box-hd d-flex">
                  <h6 class="mb-0 pp text-pink">
                    3 ตัวบน
                  </h6>

                  <div>
                    [{{
                      items.filter((ele) => ele.num_type == "3ตัวบน").length
                    }}]
                  </div>
                </div>
                <div class="stake-list p-50">
                  <div class="stake-header">
                    <div class="stake-no" />
                    <div class="stake-number text-pink-1">
                      เลขที่แทง
                    </div>
                    <div class="stake-rate text-pink-1">
                      อัตราจ่าย
                    </div>
                    <div class="stake-win-rate text-pink-1">
                      ชนะ
                    </div>
                    <div class="stake-amount text-pink-1">
                      จำนวนเงิน
                    </div>
                    <div class="stake-action text-pink-1" />
                  </div>
                  <validation-observer ref="BetItemsRulesU3">
                    <div
                      v-for="(item, index) in items.filter(
                        (ele) => ele.num_type == '3ตัวบน'
                      )"
                      :key="item.id"
                      class="stake-item"
                    >
                      <div class="stake-no">
                        {{ index + 1 }}
                      </div>
                      <div class="stake-number">
                        {{ item.num_lot }}
                      </div>
                      <div class="stake-rate">
                        <span class="rate-wrapper">{{ item.win_rate }}</span>
                      </div>
                      <div class="stake-win-rate">
                        <span class="rate-wrapper">{{
                          Commas(item.win_rate * item.price)
                        }}</span>
                      </div>
                      <div class="stake-amount">
                        <validation-provider
                          v-if="!confirm"
                          #default="{ errors }"
                          :rules="`required|between:${
                            CheckAmount(item.num_type).Min
                          },${CheckAmount(item.num_type).Max}`"
                          name="Number between"
                        >
                          <b-form-input
                            v-model="item.price"
                            type="number"
                            class="amount-input"
                            placeholder=""
                            style="color: #000"
                            @change="
                              CheckAmount(
                                item.num_type,
                                item.price,
                                index,
                                item.num_lot
                              )
                            "
                          />
                          <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                          <small class="text-danger">{{
                            errors[0] ||
                              TestPrice(
                                item.price,
                                CheckAmount(item.num_type).Min,
                                CheckAmount(item.num_type).Max
                              ) === true
                              ? `ระบุจำนวนเงิน ${Commas(
                                CheckAmount(item.num_type).Min
                              )}-${Commas(
                                CheckAmount(item.num_type).Max
                              )} ${currency_unit}`
                              : ""
                          }}</small>
                        </validation-provider>
                        <p
                          v-else
                          class="text-black m-0"
                        >
                          {{ item.price }}
                        </p>

                        <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                      </div>
                      <div class="stake-action">
                        <i
                          class="far fa-trash-alt"
                          style="color: #a6a6a6"
                          @click="
                            RemovePush(items, item.num_lot, item.num_type),
                            DisableInList(
                              item.bet_digit,
                              item.bet_type,
                              item.num_lot
                            )
                          "
                        />
                      </div>

                      <!-- {{ item.win }} -->
                    </div>
                  </validation-observer>
                </div>
              </div>
            </div>

            <div
              v-if="
                items.filter((ele) => ele.num_type == '3ตัวโต๊ด').length > 0
              "
              class="px-1 pb-1"
            >
              <div class="box-nx">
                <div class="box-hd d-flex">
                  <h6 class="mb-0 pp text-pink">
                    3 ตัวโต๊ด
                  </h6>

                  <div>
                    [{{
                      items.filter((ele) => ele.num_type == "3ตัวโต๊ด").length
                    }}]
                  </div>
                </div>
                <div class="stake-list p-50">
                  <div class="stake-header">
                    <div class="stake-no" />
                    <div class="stake-number text-pink-1">
                      เลขที่แทง
                    </div>
                    <div class="stake-rate text-pink-1">
                      อัตราจ่าย
                    </div>
                    <div class="stake-win-rate text-pink-1">
                      ชนะ
                    </div>
                    <div class="stake-amount text-pink-1">
                      จำนวนเงิน
                    </div>
                    <div class="stake-action" />
                  </div>
                  <validation-observer ref="BetItemsRulesT3">
                    <div
                      v-for="(item, index) in items.filter(
                        (ele) => ele.num_type == '3ตัวโต๊ด'
                      )"
                      :key="item.id"
                      class="stake-item"
                    >
                      <div class="stake-no">
                        {{ index + 1 }}
                      </div>
                      <div class="stake-number">
                        {{ item.num_lot }}
                      </div>
                      <div class="stake-rate">
                        <span class="rate-wrapper">{{ item.win_rate }}</span>
                      </div>
                      <div class="stake-win-rate">
                        <span class="rate-wrapper">{{
                          Commas(item.win_rate * item.price)
                        }}</span>
                      </div>
                      <div class="stake-amount">
                        <validation-provider
                          v-if="!confirm"
                          #default="{ errors }"
                          :rules="`required|between:${
                            CheckAmount(item.num_type).Min
                          },${CheckAmount(item.num_type).Max}`"
                          name="Number between"
                        >
                          <b-form-input
                            v-model="item.price"
                            type="number"
                            class="amount-input"
                            placeholder=""
                            style="color: #000"
                            @change="
                              CheckAmount(
                                item.num_type,
                                item.price,
                                index,
                                item.num_lot
                              )
                            "
                          />
                          <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                          <small class="text-danger">{{
                            errors[0] ||
                              TestPrice(
                                item.price,
                                CheckAmount(item.num_type).Min,
                                CheckAmount(item.num_type).Max
                              ) === true
                              ? `ระบุจำนวนเงิน ${Commas(
                                CheckAmount(item.num_type).Min
                              )}-${Commas(
                                CheckAmount(item.num_type).Max
                              )} ${currency_unit}`
                              : ""
                          }}</small>
                        </validation-provider>
                        <p
                          v-else
                          class="text-black m-0"
                        >
                          {{ item.price }}
                        </p>
                        <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                      </div>
                      <div class="stake-action">
                        <i
                          class="far fa-trash-alt"
                          style="color: #a6a6a6"
                          @click="
                            RemovePush(items, item.num_lot, item.num_type),
                            DisableInList(
                              item.bet_digit,
                              item.bet_type,
                              item.num_lot
                            )
                          "
                        />
                      </div>

                      {{ item.win }}
                    </div>
                  </validation-observer>
                </div>
              </div>
            </div>

            <div
              v-if="items.filter((ele) => ele.num_type == '2ตัวบน').length > 0"
              class="px-1 pb-1"
            >
              <div class="box-nx">
                <div class="box-hd d-flex">
                  <h6 class="mb-0 pp text-pink">
                    2 ตัวบน
                  </h6>

                  <div>
                    [{{
                      items.filter((ele) => ele.num_type == "2ตัวบน").length
                    }}]
                  </div>
                </div>
                <div class="stake-list p-50">
                  <div class="stake-header">
                    <div class="stake-no" />
                    <div class="stake-number text-pink-1">
                      เลขที่แทง
                    </div>
                    <div class="stake-rate text-pink-1">
                      อัตราจ่าย
                    </div>
                    <div class="stake-win-rate text-pink-1">
                      ชนะ
                    </div>
                    <div class="stake-amount text-pink-1">
                      จำนวนเงิน
                    </div>
                    <div class="stake-action" />
                  </div>
                  <validation-observer ref="BetItemsRulesU2">
                    <div
                      v-for="(item, index) in items.filter(
                        (ele) => ele.num_type == '2ตัวบน'
                      )"
                      :key="item.num_lot"
                      class="stake-item"
                    >
                      <div class="stake-no">
                        {{ index + 1 }}
                      </div>
                      <div class="stake-number">
                        {{ item.num_lot }}
                      </div>
                      <div class="stake-rate">
                        <span class="rate-wrapper">{{ item.win_rate }}</span>
                      </div>
                      <div class="stake-win-rate">
                        <span class="rate-wrapper">{{
                          Commas(item.win_rate * item.price)
                        }}</span>
                      </div>
                      <div class="stake-amount">
                        <validation-provider
                          v-if="!confirm"
                          #default="{ errors }"
                          :rules="`required|between:${
                            CheckAmount(item.num_type).Min
                          },${CheckAmount(item.num_type).Max}`"
                          name="Number between"
                        >
                          <b-form-input
                            v-model="item.price"
                            type="number"
                            class="amount-input"
                            placeholder=""
                            style="color: #000"
                            @change="
                              CheckAmount(
                                item.num_type,
                                item.price,
                                index,
                                item.num_lot
                              )
                            "
                          />
                          <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                          <small class="text-danger">{{
                            errors[0] ||
                              TestPrice(
                                item.price,
                                CheckAmount(item.num_type).Min,
                                CheckAmount(item.num_type).Max
                              ) === true
                              ? `ระบุจำนวนเงิน ${Commas(
                                CheckAmount(item.num_type).Min
                              )}-${Commas(
                                CheckAmount(item.num_type).Max
                              )} ${currency_unit}`
                              : ""
                          }}</small>
                        </validation-provider>
                        <p
                          v-else
                          class="text-black m-0"
                        >
                          {{ item.price }}
                        </p>
                        <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                      </div>
                      <div class="stake-action">
                        <i
                          class="far fa-trash-alt"
                          style="color: #a6a6a6"
                          @click="
                            RemovePush(items, item.num_lot, item.num_type),
                            DisableInList(
                              item.bet_digit,
                              item.bet_type,
                              item.num_lot
                            )
                          "
                        />
                      </div>

                      {{ item.win }}
                    </div>
                  </validation-observer>
                </div>
              </div>
            </div>

            <div
              v-if="
                items.filter((ele) => ele.num_type == '2ตัวล่าง').length > 0
              "
              class="px-1 pb-1"
            >
              <div class="box-nx">
                <div class="box-hd d-flex">
                  <h6 class="mb-0 pp text-pink">
                    2 ตัวล่าง
                  </h6>

                  <div>
                    [{{
                      items.filter((ele) => ele.num_type == "2ตัวล่าง").length
                    }}]
                  </div>
                </div>
                <div class="stake-list p-50">
                  <div class="stake-header">
                    <div class="stake-no" />
                    <div class="stake-number text-pink-1">
                      เลขที่แทง
                    </div>
                    <div class="stake-rate text-pink-1">
                      อัตราจ่าย
                    </div>
                    <div class="stake-win-rate text-pink-1">
                      ชนะ
                    </div>
                    <div class="stake-amount text-pink-1">
                      จำนวนเงิน
                    </div>
                    <div class="stake-action" />
                  </div>
                  <validation-observer ref="BetItemsRulesD2">
                    <div
                      v-for="(item, index) in items.filter(
                        (ele) => ele.num_type == '2ตัวล่าง'
                      )"
                      :key="item.num_lot"
                      class="stake-item"
                    >
                      <div class="stake-no">
                        {{ index + 1 }}
                      </div>
                      <div class="stake-number">
                        {{ item.num_lot }}
                      </div>
                      <div class="stake-rate">
                        <span class="rate-wrapper">{{ item.win_rate }}</span>
                      </div>
                      <div class="stake-win-rate">
                        <span class="rate-wrapper">{{
                          Commas(item.win_rate * item.price)
                        }}</span>
                      </div>
                      <div class="stake-amount">
                        <validation-provider
                          v-if="!confirm"
                          #default="{ errors }"
                          :rules="`required|between:${
                            CheckAmount(item.num_type).Min
                          },${CheckAmount(item.num_type).Max}`"
                          name="Number between"
                        >
                          <b-form-input
                            v-model="item.price"
                            type="number"
                            class="amount-input"
                            placeholder=""
                            style="color: #000"
                            @change="
                              CheckAmount(
                                item.num_type,
                                item.price,
                                index,
                                item.num_lot
                              )
                            "
                          />
                          <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                          <small class="text-danger">{{
                            errors[0] ||
                              TestPrice(
                                item.price,
                                CheckAmount(item.num_type).Min,
                                CheckAmount(item.num_type).Max
                              ) === true
                              ? `ระบุจำนวนเงิน ${Commas(
                                CheckAmount(item.num_type).Min
                              )}-${Commas(
                                CheckAmount(item.num_type).Max
                              )} ${currency_unit}`
                              : ""
                          }}</small>
                        </validation-provider>
                        <p
                          v-else
                          class="text-black m-0"
                        >
                          {{ item.price }}
                        </p>
                        <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                      </div>
                      <div class="stake-action">
                        <i
                          class="far fa-trash-alt"
                          style="color: #a6a6a6"
                          @click="
                            RemovePush(items, item.num_lot, item.num_type),
                            DisableInList(
                              item.bet_digit,
                              item.bet_type,
                              item.num_lot
                            )
                          "
                        />
                      </div>

                      {{ item.win }}
                    </div>
                  </validation-observer>
                </div>
              </div>
            </div>

            <div
              v-if="items.filter((ele) => ele.num_type == 'วิ่งบน').length > 0"
              class="px-1 pb-1"
            >
              <div class="box-nx">
                <div class="box-hd d-flex">
                  <h6 class="mb-0 pp text-pink">
                    วิ่งบน
                  </h6>

                  <div>
                    [{{
                      items.filter((ele) => ele.num_type == "วิ่งบน").length
                    }}]
                  </div>
                </div>
                <div class="stake-list p-50">
                  <div class="stake-header">
                    <div class="stake-no" />
                    <div class="stake-number text-pink-1">
                      เลขที่แทง
                    </div>
                    <div class="stake-rate text-pink-1">
                      อัตราจ่าย
                    </div>
                    <div class="stake-win-rate text-pink-1">
                      ชนะ
                    </div>
                    <div class="stake-amount text-pink-1">
                      จำนวนเงิน
                    </div>
                    <div class="stake-action" />
                  </div>
                  <validation-observer ref="BetItemsRulesU1">
                    <div
                      v-for="(item, index) in items.filter(
                        (ele) => ele.num_type == 'วิ่งบน'
                      )"
                      :key="item.num_lot"
                      class="stake-item"
                    >
                      <div class="stake-no">
                        {{ index + 1 }}
                      </div>
                      <div class="stake-number">
                        {{ item.num_lot }}
                      </div>
                      <div class="stake-rate">
                        <span class="rate-wrapper">{{ item.win_rate }}</span>
                      </div>
                      <div class="stake-win-rate">
                        <span class="rate-wrapper">{{
                          Commas(item.win_rate * item.price)
                        }}</span>
                      </div>
                      <div class="stake-amount">
                        <validation-provider
                          v-if="!confirm"
                          #default="{ errors }"
                          :rules="`required|between:${
                            CheckAmount(item.num_type).Min
                          },${CheckAmount(item.num_type).Max}`"
                          name="Number between"
                        >
                          <b-form-input
                            v-model="item.price"
                            type="number"
                            class="amount-input"
                            placeholder=""
                            style="color: #000"
                            @change="
                              CheckAmount(
                                item.num_type,
                                item.price,
                                index,
                                item.num_lot
                              )
                            "
                          />
                          <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                          <small class="text-danger">{{
                            errors[0] ||
                              TestPrice(
                                item.price,
                                CheckAmount(item.num_type).Min,
                                CheckAmount(item.num_type).Max
                              ) === true
                              ? `ระบุจำนวนเงิน ${Commas(
                                CheckAmount(item.num_type).Min
                              )}-${Commas(
                                CheckAmount(item.num_type).Max
                              )} ${currency_unit}`
                              : ""
                          }}</small>
                        </validation-provider>
                        <p
                          v-else
                          class="text-black m-0"
                        >
                          {{ item.price }}
                        </p>
                        <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                      </div>
                      <div class="stake-action">
                        <i
                          class="far fa-trash-alt"
                          style="color: #a6a6a6"
                          @click="
                            RemovePush(items, item.num_lot, item.num_type),
                            DisableInList(
                              item.bet_digit,
                              item.bet_type,
                              item.num_lot
                            )
                          "
                        />
                      </div>

                      {{ item.win }}
                    </div>
                  </validation-observer>
                </div>
              </div>
            </div>

            <div
              v-if="
                items.filter((ele) => ele.num_type == 'วิ่งล่าง').length > 0
              "
              class="px-1"
            >
              <div class="box-nx">
                <div class="box-hd d-flex">
                  <h6 class="mb-0 pp text-pink">
                    วิ่งล่าง
                  </h6>

                  <div>
                    [{{
                      items.filter((ele) => ele.num_type == "วิ่งล่าง").length
                    }}]
                  </div>
                </div>
                <div class="stake-list p-50">
                  <div class="stake-header">
                    <div class="stake-no" />
                    <div class="stake-number text-pink-1">
                      เลขที่แทง
                    </div>
                    <div class="stake-rate text-pink-1">
                      อัตราจ่าย
                    </div>
                    <div class="stake-win-rate text-pink-1">
                      ชนะ
                    </div>
                    <div class="stake-amount text-pink-1">
                      จำนวนเงิน
                    </div>
                    <div class="stake-action" />
                  </div>
                  <validation-observer ref="BetItemsRulesD1">
                    <div
                      v-for="(item, index) in items.filter(
                        (ele) => ele.num_type == 'วิ่งล่าง'
                      )"
                      :key="item.num_lot"
                      class="stake-item"
                    >
                      <div class="stake-no">
                        {{ index + 1 }}
                      </div>
                      <div class="stake-number">
                        {{ item.num_lot }}
                      </div>
                      <div class="stake-rate">
                        <span class="rate-wrapper">{{ item.win_rate }}</span>
                      </div>
                      <div class="stake-win-rate">
                        <span class="rate-wrapper">{{
                          Commas(item.win_rate * item.price)
                        }}</span>
                      </div>
                      <div class="stake-amount">
                        <validation-provider
                          v-if="!confirm"
                          #default="{ errors }"
                          :rules="`required|between:${
                            CheckAmount(item.num_type).Min
                          },${CheckAmount(item.num_type).Max}`"
                          name="Number between"
                        >
                          <b-form-input
                            v-model="item.price"
                            type="number"
                            class="amount-input"
                            placeholder=""
                            style="color: #000"
                            @change="
                              CheckAmount(
                                item.num_type,
                                item.price,
                                index,
                                item.num_lot
                              )
                            "
                          />
                          <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                          <small class="text-danger">{{
                            errors[0] ||
                              TestPrice(
                                item.price,
                                CheckAmount(item.num_type).Min,
                                CheckAmount(item.num_type).Max
                              ) === true
                              ? `ระบุจำนวนเงิน ${Commas(
                                CheckAmount(item.num_type).Min
                              )}-${Commas(
                                CheckAmount(item.num_type).Max
                              )} ${currency_unit}`
                              : ""
                          }}</small>
                        </validation-provider>
                        <p
                          v-else
                          class="text-black m-0"
                        >
                          {{ item.price }}
                        </p>
                        <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                      </div>
                      <div class="stake-action">
                        <i
                          class="far fa-trash-alt"
                          style="color: #a6a6a6"
                          @click="
                            RemovePush(items, item.num_lot, item.num_type),
                            DisableInList(
                              item.bet_digit,
                              item.bet_type,
                              item.num_lot
                            )
                          "
                        />
                      </div>

                      {{ item.win }}
                    </div>
                  </validation-observer>
                </div>
              </div>
            </div>
          </div>
          <div class="padding-bottom-10" />
        </div>
      </div>

      <!-- footer -->

      <div
        v-if="!payOut"
        v-show="!show"
        class="bottom-bar"
      >
        <router-link
          class="box-detail cursor-pointer"
          :to="{ name: 'tanghuay-round' }"
        >
          <i class="far fa-chevron-left" />
        </router-link>
        <div
          class="box-back cursor-pointer"
          @click="showRate()"
        >
          อัตราจ่าย
        </div>
        <div
          class="box-price"
          @click="payOut = !payOut"
        >
          ใส่ราคา
        </div>
      </div>

      <div
        v-if="payOut && !confirm"
        v-show="!show"
        class="bottom-bar-2"
      >
        <div class="d-flex justify-content-between">
          <div>คงเหลือ {{ Commas(MainWallet) }} {{ UserData.currency == 'KRW' ? 'วอน': 'บาท' }}</div>
          <div class="text-num-price">
            รวม {{ Commas(CalSumAmount()) }} {{ UserData.currency }}
          </div>
        </div>

        <div class="input-price">
          <div class="input-price-text">
            ราคาเท่ากัน
          </div>

          <div class="input-box">
            <input
              v-model="pricebase"
              type="number"
              value="5"
              maxlength="7"
              @input="AddPriceBase(pricebase)"
            >
          </div>
        </div>

        <div class="stake-amount-group my-75">
          <button
            :disabled="CheckSubmit != true"
            type="button"
            class="btnw btn-sms btn-oprice"
            @click="AddPriceBase(5 * BaseAmount, (pricebase = 5 * BaseAmount))"
          >
            {{ 5 * BaseAmount }}
          </button>
          <button
            :disabled="CheckSubmit != true"
            type="button"
            class="btnw btn-sms btn-oprice"
            @click="
              AddPriceBase(10 * BaseAmount, (pricebase = 10 * BaseAmount))
            "
          >
            {{ 10 * BaseAmount }}
          </button>
          <button
            :disabled="CheckSubmit != true"
            type="button"
            class="btnw btn-sms btn-oprice"
            @click="
              AddPriceBase(20 * BaseAmount, (pricebase = 20 * BaseAmount))
            "
          >
            {{ 20 * BaseAmount }}
          </button>
          <button
            :disabled="CheckSubmit != true"
            type="button"
            class="btnw btn-sms btn-oprice"
            @click="
              AddPriceBase(50 * BaseAmount, (pricebase = 50 * BaseAmount))
            "
          >
            {{ 50 * BaseAmount }}
          </button>
          <button
            :disabled="CheckSubmit != true"
            type="button"
            class="btnw btn-sms btn-oprice"
            @click="
              AddPriceBase(100 * BaseAmount, (pricebase = 100 * BaseAmount))
            "
          >
            {{ 100 * BaseAmount }}
          </button>
        </div>

        <div
          v-show="!show"
          class="d-flex"
          style="gap: 10px"
        >
          <div
            class="box-detail cursor-pointer"
            style="font-size: 12px"
            @click="
              $refs['modal-undo'].show(), (payOut = !payOut), (confirm = false)
            "
          >
            ล้าง
          </div>
          <div
            class="box-back"
            @click="(payOut = !payOut), (confirm = false)"
          >
            แก้ไขเลข
          </div>
          <div
            class="box-price"
            @click="PreSubmit()"
          >
            ส่งโพย
          </div>
        </div>
      </div>

      <div
        v-if="confirm == true"
        v-show="!show"
        class="bottom-bar"
      >
        <div
          class="box-detail cursor-pointer"
          style="font-size: 12px"
          @click="
            $refs['modal-undo'].show(), (payOut = !payOut), (confirm = false)
          "
        >
          ล้าง
        </div>
        <div
          class="box-back"
          @click="confirm = false"
        >
          ยกเลิก
        </div>
        <div
          class="box-price"
          @click="SummitBet(), (payOut = !payOut)"
        >
          ยืนยัน
        </div>
      </div>

      <!-- modal alert -->
      <b-modal
        ref="modal-alert"
        hide-footer
        hide-header
        centered
        size="sm"
        title="Using Component Methods"
      >
        <div class="text-center mt-2 mb-2">
          <b-avatar
            size="100"
            icon="AlertCircleIcon"
            class="mb-1"
            variant="warning"
          >
            <feather-icon
              icon="AlertCircleIcon"
              size="100"
            />
          </b-avatar>

          <h3 class="font-weight-bolder text-warning mt-1">
            แจ้งเตือน
          </h3>
        </div>
        <table
          style="width: 100%"
          class="pl-1 pr-1 mb-1"
        >
          <tbody>
            <tr>
              <td
                colspan="2"
                class="text-center"
              >
                <h4 class="font-weight-bolder">
                  {{ Slip.LottoHeadName }}
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <small>วันที่</small>
                <h4 class="font-weight-bolder">
                  {{ Slip.date }}
                </h4>
              </td>
              <td>
                <small>เวลา</small>
                <h4 class="font-weight-bolder">
                  {{ Slip.time }} น.
                </h4>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <small>โพยเลขที่</small>
                <h4 class="font-weight-bolder">
                  #{{ Slip.listID }}
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <small>รายการแทง</small>
                <h4 class="font-weight-bolder">
                  {{ Slip.count }} รายการ
                </h4>
              </td>
              <td>
                <small>ยอดเดิมพันทั้งหมด</small>
                <h4 class="font-weight-bolder">
                  {{ Slip.SumAmount }} {{ currency_unit }}
                </h4>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="LimitRes.length > 0"
          class="text-center"
        >
          <h3 class="text-black">
            แจ้งเตือนปิดรับแทง !!
          </h3>
          <p class="text-muted">
            เนื่องจากเลขดังกล่าวได้ถึงยอดที่รับแทงแล้ว
          </p>
          <div class="table-responsive">
            <b-table
              small
              hover
              class="bg-white"
              :items="LimitRes"
              :fields="fieldsModal"
            >
              <template v-slot:cell(EmptyAmount)="data">
                {{ Commas(data.item.EmptyAmount) }}
              </template>
            </b-table>
          </div>
        </div>

        <div
          v-if="ChangRate.length > 0"
          class="text-center"
        >
          <h3 class="text-white">
            แจ้งเตือนเปลี่ยนอัตราจ่าย !!
          </h3>
          <div class="table-responsive">
            <b-table
              small
              hover
              class="bg-white"
              responsive="sm"
              :items="ChangRate"
              :fields="fieldsChangeRate"
            >
              <template v-slot:cell(CurrentBet)="data">
                {{ Commas(data.item.CurrentBet) }}
              </template>
              <template v-slot:cell(NextAmount)="data">
                {{ Commas(data.item.NextAmount) }}
              </template>
            </b-table>
          </div>
        </div>

        <div class="row">
          <div class="col-6 mx-auto">
            <router-link
              :to="{ path: `/tanghuay-online/list-detail/${Slip.listID}` }"
            >
              <b-button
                block
                class="btn-ok"
              >
                ดูโพย
              </b-button>
            </router-link>
          </div>

          <div class="col-6">
            <b-button
              class="btn-yes-2 mr-2"
              block
              style="color: black !important; margin-left: -10px !important"
              @click="hideModalAlert"
            >
              แทงหวยต่อ
            </b-button>
          </div>
        </div>
      </b-modal>

      <!-- ต้องการที่จะล้างโพยใช่หรือไม่ -->

      <b-modal
        ref="modal-undo"
        hide-footer
        hide-header
        centered
        size="sm"
        title="Using Component Methods"
      >
        <div class="content-box">
          <h4>ต้องการที่จะล้างโพยใช่หรือไม่ !!</h4>
          <p>ท่านต้องการที่จะล้างโพย กรุณายืนยันอีกครั้ง</p>
        </div>

        <div
          class="mt-1 d-flex"
          style="gap: 10px"
        >
          <div class="w-50">
            <button
              class="btn-cancle"
              @click="$refs['modal-undo'].hide()"
            >
              ยกเลิก
            </button>
          </div>

          <div class="w-50">
            <button
              class="btn-tang"
              @click="Undo()"
            >
              ตกลง
            </button>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="modal-price"
        hide-footer
        hide-header
        centered
        size="sm"
        title="Using Component Methods"
      >
        <div class="content-box">
          <h4>ส่งโพยไม่สำเร็จ !!</h4>
          <p>{{ mes_err }}</p>
        </div>

        <div class="text-center mt-2">
          <button
            class="btn-confirm"
            @click="$refs['modal-price'].hide()"
          >
            ตกลง
          </button>
        </div>
      </b-modal>

      <b-modal
        ref="modal-limit"
        hide-footer
        hide-header
        centered
        size="sm"
        title="Using Component Methods"
      >
        <div class="content-box">
          <h4>แจ้งเตือนปิดรับแทง !!</h4>
          <p>เนื่องจากเลขดังกล่าวได้ถึงยอดที่รับแทงแล้ว</p>
        </div>

        <div class="table-responsive">
          <b-table
            small
            hover
            class="bg-white"
            :items="LimitRes"
            :fields="fieldsModal"
          />
        </div>

        <div class="text-center mt-2">
          <button
            class="btn-confirm"
            @click="$refs['modal-limit'].hide()"
          >
            เข้าใจเเล้ว
          </button>
        </div>
      </b-modal>

      <b-modal
        ref="my-modal"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <div class="text-center mb-2">
          <div>
            <img
              src="/images/gif/success.gif"
              alt=""
              height="120"
            >
          </div>

          <h3 class="font-weight-bolder text-black">
            ทำรายการแทงเรียบร้อย
          </h3>
          <p class="m-0 text-mp">
            ระบบได้ทำการส่งโพยของท่านให้เรียบร้อยแล้ว
          </p>
        </div>

        <div class="new-doc">
          <div class="new-hd__top">
            <div class="new-detail__title">
              หวย{{ Slip.LottoHeadName }}
            </div>
            <div class="new-list">
              10 รายการ
            </div>
          </div>

          <div class="inder-boder">
            <div class="mb-1">
              <p class="m-0">
                โพยเลขที่
              </p>
              <div class="text-inner">
                #{{ Slip.listID }}
              </div>
            </div>

            <div class="mb-1">
              <p class="m-0">
                วันที่ / เวลา ทำรายการ
              </p>
              <div class="text-inner">
                {{ Slip.date }} {{ Slip.time }} น.
              </div>
            </div>

            <div>
              <p class="m-0">
                ยอดเดิมพันทั้งหมด
              </p>
              <div class="text-inner-price">
                {{ Slip.SumAmount }} {{ currency_unit }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="mt-1 d-flex"
          style="gap: 10px"
        >
          <div class="w-50">
            <router-link
              :to="{ path: `/tanghuay-online/list-detail/${Slip.listID}` }"
            >
              <button class="btn-cancle">
                ดูโพย
              </button>
            </router-link>
          </div>

          <div class="w-50">
            <button
              class="btn-tang"
              @click="hideModal"
            >
              แทงหวยต่อ
            </button>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="my-rule"
        centered
        hide-footer
        hide-header
      >
        <div class="mt-2 mb-2">
          <div
            class="text-black"
            v-html="rulesText"
          />
        </div>
        <div class="row">
          <div class="col-6 mx-auto">
            <b-button
              block
              class="btn-ok"
              style="color: #fff !important"
              @click="hideModalRule"
            >
              ยืนยัน
            </b-button>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="my-percent"
        centered
        size="sm"
        hide-footer
        hide-header
      >
        <div class="content-box">
          <h4>หมายเลขจ่ายตามเปอร์เซนต์</h4>
          <p>
            {{ n_type }} หมายเลข {{ n_bet }} จ่าย {{ n_rate }}
            {{ currency_unit }}
          </p>
        </div>

        <div class="text-center mt-2">
          <button
            class="btn-confirm"
            @click="$refs['my-percent'].hide()"
          >
            เข้าใจเเล้ว
          </button>
        </div>
      </b-modal>

      <b-modal
        ref="my-warning"
        centered
        size="sm"
        hide-footer
        hide-header
      >
        <div class="content-box">
          <h4>จำนวนเงินไม่เพียงพอ !!</h4>
          <p>ยอดเงินของท่านไม่เพียงพอกรุณาฝากเงินก่อนค่ะ</p>
        </div>

        <div class="text-center mt-2">
          <button
            class="btn-confirm"
            @click="$refs['my-warning'].hide()"
          >
            เข้าใจเเล้ว
          </button>
        </div>
      </b-modal>

      <b-modal
        ref="my-warning-3win"
        centered
        size="sm"
        hide-footer
        hide-header
      >
        <div class="content-box">
          <h4>กรอกเลขอย่างน้อย 3 ตัว</h4>
          <!-- <p>ยอดเงินของท่านไม่เพียงพอกรุณาฝากเงินก่อนค่ะ</p> -->
        </div>

        <div class="text-center mt-2">
          <button
            class="btn-confirm"
            @click="$refs['my-warning-3win'].hide()"
          >
            เข้าใจเเล้ว
          </button>
        </div>
      </b-modal>

      <b-modal
        ref="my-warning-2win"
        centered
        size="sm"
        hide-footer
        hide-header
      >
        <div class="content-box">
          <h4>กรอกเลขอย่างน้อย 2 ตัว</h4>
          <!-- <p>ยอดเงินของท่านไม่เพียงพอกรุณาฝากเงินก่อนค่ะ</p> -->
        </div>

        <div class="text-center mt-2">
          <button
            class="btn-confirm"
            @click="$refs['my-warning-2win'].hide()"
          >
            เข้าใจเเล้ว
          </button>
        </div>
      </b-modal>

      <b-modal
        ref="my-rate"
        centered
        size="sm"
        hide-footer
        hide-header
      >
        <div class="content-box">
          <h4>อัตราการจ่าย</h4>

          <hr>

          <div class="box-rate mb-1">
            <div class="box-head">
              <p>3 ตัวบน</p>
              <span>จ่าย: {{ RateAll.Three.Up.Rate }}</span>
            </div>

            <div class="box-body">
              <p>แทงขั้นต่ำต่อครั้ง/เลข : {{ Commas(RateAll.Three.Up.Min) }}</p>
              <p class="m-0">
                แทงสูงสุดต่อครั้ง/เลข : {{ Commas(RateAll.Three.Up.Max) }}
              </p>
            </div>
          </div>

          <div class="box-rate mb-1">
            <div class="box-head">
              <p>3 ตัวโต๊ด</p>
              <span>จ่าย: {{ RateAll.Three.Tot.Rate }}</span>
            </div>

            <div class="box-body">
              <p>
                แทงขั้นต่ำต่อครั้ง/เลข : {{ Commas(RateAll.Three.Tot.Min) }}
              </p>
              <p class="m-0">
                แทงสูงสุดต่อครั้ง/เลข : {{ Commas(RateAll.Three.Tot.Max) }}
              </p>
            </div>
          </div>

          <div class="box-rate mb-1">
            <div class="box-head">
              <p>2 ตัวบน</p>
              <span>จ่าย: {{ RateAll.Two.Up.Rate }}</span>
            </div>

            <div class="box-body">
              <p>แทงขั้นต่ำต่อครั้ง/เลข : {{ Commas(RateAll.Two.Up.Min) }}</p>
              <p class="m-0">
                แทงสูงสุดต่อครั้ง/เลข : {{ Commas(RateAll.Two.Up.Max) }}
              </p>
            </div>
          </div>

          <div class="box-rate mb-1">
            <div class="box-head">
              <p>2 ตัวล่าง</p>
              <span>จ่าย: {{ RateAll.Two.Down.Rate }}</span>
            </div>

            <div class="box-body">
              <p>แทงขั้นต่ำต่อครั้ง/เลข : {{ Commas(RateAll.Two.Down.Min) }}</p>
              <p class="m-0">
                แทงสูงสุดต่อครั้ง/เลข : {{ Commas(RateAll.Two.Down.Max) }}
              </p>
            </div>
          </div>

          <div class="box-rate mb-1">
            <div class="box-head">
              <p>วิ่งบน</p>
              <span>จ่าย: {{ RateAll.One.Up.Rate }}</span>
            </div>

            <div class="box-body">
              <p>แทงขั้นต่ำต่อครั้ง/เลข : {{ Commas(RateAll.One.Up.Min) }}</p>
              <p class="m-0">
                แทงสูงสุดต่อครั้ง/เลข : {{ Commas(RateAll.One.Up.Max) }}
              </p>
            </div>
          </div>

          <div class="box-rate mb-1">
            <div class="box-head">
              <p>วิ่งล่าง</p>
              <span>จ่าย: {{ RateAll.One.Down.Rate }}</span>
            </div>

            <div class="box-body">
              <p>แทงขั้นต่ำต่อครั้ง/เลข : {{ Commas(RateAll.One.Down.Min) }}</p>
              <p class="m-0">
                แทงสูงสุดต่อครั้ง/เลข : {{ Commas(RateAll.One.Down.Max) }}
              </p>
            </div>
          </div>
        </div>

        <div class="text-center mt-2">
          <button
            class="btn-confirm"
            @click="$refs['my-rate'].hide()"
          >
            ยืนยัน
          </button>
        </div>
      </b-modal>
      <!-- <this-footer /> -->
    </b-overlay>
    <div class="padding-bottom-10" />
  </div>
</template>

<script>
import { required, between, digits } from '@validations'
import {
  BModal,
  BTable,
  BFormInput,
  BAvatar,
  VBToggle,
  BButton,
  // BFormCheckbox,
  BOverlay,
  BIconController,
  BRow,
  BCol,
  BCollapse,
  // BCard,
  // BContainer,
  // BListGroup,
  // BListGroupItem,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VueSweetalert2 from 'vue-sweetalert2'

// import vSelect from 'vue-select'
// import 'sweetalert2/dist/sweetalert2.min.css'
// import 'swiper/css/swiper.css'
import momenttz from 'moment-timezone'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OtpInput from '@8bu/vue-otp-input'
// import ThisHeader from './component/ThisHeader.vue'
// import ThisFooter from './component/ThisFooter.vue'

// import SidebarContent from './SidebarContent.vue'
// import SidebarContentFooter from './SidebarContentFooter.vue'
import Ws from '@adonisjs/websocket-client'
import index from 'vue-prism-component'

export default {
  components: {
    // OtherMethod,
    BOverlay,
    BIconController,
    OtpInput,
    BModal,
    BTable,
    BButton,
    BFormInput,
    BAvatar,
    // ThisHeader,
    // ThisFooter,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCollapse,
    // BCard,
    // BContainer,
    // VueSweetalert2,
    // BListGroup,
    // BListGroupItem,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      // Win: false,
      winActive: 'win2',
      n_type: null,
      n_bet: null,
      n_rate: null,
      mes_warning: null,
      mes_err: null,
      currency_unit: 'บาท',
      Mode: 'tap1',
      dialPad: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'ล้างโพย',
        '0',
        'ลบ',
      ],
      isShowNumber: false,
      remark: null,
      discount_member: 0,
      discount: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      rule: null,
      confirm: false,
      required,
      between,
      digits,
      payOut: false,
      CheckSubmit: true,
      BugOtp: true,
      show: false,
      rulesText: '',
      fieldsModal: [
        { key: 'type', label: 'ประเภท' },
        { key: 'NumBet', label: 'เลขที่แทง' },
        { key: 'EmptyAmount', label: 'รับแทงได้' },
      ],

      fieldsChangeRate: [
        { key: 'type', label: 'ประเภท' },
        { key: 'NumBet', label: 'เลขที่แทง' },
        { key: 'CurrentBet', label: 'รับแทงได้' },
        { key: 'CurrentRate', label: 'เรทเดิม' },
        { key: 'NextAmount', label: 'ยอดปรับเรท' },
        { key: 'NextRate', label: 'ราคาเรทต่อไป' },
      ],
      SumAmount: 0,
      AmountBet: null,
      NumBet: '',
      CheckBetType: true,
      maxlength: 3,
      Interval: null,
      items: [],
      RateAll: {
        Three: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down_f: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down_b: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        Two: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        One: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
      },
      Title: {
        RoundID: '',
        LottoName: '',
        LottoTypeHead: null,
        LottoHead: null,
        LottoSubHead: null,
        LottoDetail: [],
        CloseBet: '',
        CloseDate: '',
        CloseDateName: '',
        ShowCloseDate: '',
        LimitU3: [],
        LimitD3: [],
        LimitB3: [],
        LimitT3: [],
        LimitU2: [],
        LimitD2: [],
        LimitT2: [],
        LimitU1: [],
        LimitD1: [],
        CloseBetU3: [],
        CloseBetD3: [],
        CloseBetB3: [],
        CloseBetT3: [],
        CloseBetU2: [],
        CloseBetD2: [],
        CloseBetT2: [],
        CloseBetU1: [],
        CloseBetD1: [],
      },
      CloseBet: {
        CloseU3: [],
        CloseD3: [],
        CloseB3: [],
        CloseT3: [],
        CloseU2: [],
        CloseD2: [],
        CloseT2: [],
        CloseU1: [],
        CloseD1: [],
      },
      CloseBetModal: [],
      Slip: {
        SumAmount: null,
        date: null,
        time: null,
        listID: null,
        count: null,
        LottoHeadName: null,
      },
      MainWallet: 0,
      NameShow: '',
      CloseTimeShow: '',
      selectNumbet3U: true,
      selectNumbet3T: false,
      selectNumbet3D: false,
      selectNumbet2U: false,
      selectNumbet2T: false,
      selectNumbet2D: false,
      selectNumbet1U: false,
      selectNumbet1D: false,
      selectNumbet3U2U2D: false,
      selectNumbet3U2U2DFlip: false,
      SubThree0: true,
      SubThree1: false,
      SubThree2: false,
      SubThree3: false,
      SubThree4: false,
      SubThree5: false,
      SubThree6: false,
      SubThree7: false,
      SubThree8: false,
      SubThree9: false,
      PickThreeArr: this.GenNum(1000, 3),
      PickTwoArr: this.GenNum(100, 2),
      PickOneArr: this.GenNum(10, 1),
      PickThree: 0,
      NineDoor: {
        D0: false,
        D1: false,
        D2: false,
        D3: false,
        D4: false,
        D5: false,
        D6: false,
        D7: false,
        D8: false,
        D9: false,
      },
      RootFront: {
        D0: false,
        D1: false,
        D2: false,
        D3: false,
        D4: false,
        D5: false,
        D6: false,
        D7: false,
        D8: false,
        D9: false,
      },
      RootBack: {
        D0: false,
        D1: false,
        D2: false,
        D3: false,
        D4: false,
        D5: false,
        D6: false,
        D7: false,
        D8: false,
        D9: false,
      },
      TwoPushSubType: {
        NineDoor: false,
        RootFront: false,
        RootBack: false,
        Ble: false,
        TwoHight: false,
        TwoLow: false,
        TwoEven: false,
        TwoOdd: false,
      },
      pricebase: 0,
      HaveAutoRate: false,
      AutoRateData: {},
      LimitRes: [],
      ChangRate: [],
      BaseAmount: 1,

      apiFAKE: {
        success: false,
        LimitRes: [
          {
            type: '2ตัวบน',
            NumBet: '58',
            EmptyAmount: 50,
          },
          {
            type: '2ตัวบน',
            NumBet: '12',
            EmptyAmount: 20,
          },
          {
            type: '2ตัวล่าง',
            NumBet: '81',
            EmptyAmount: 80,
          },
        ],
        Bet: [
          {
            _id: '65c0a54ab1c39c5fc1767226',
            bet_class: 'default',
            list_id: 'uf20m1ts009_1707124041473',
            round_id: '65be2e83a8ed7f3cc847137e',
            discount: 0,
            discount_remark: 0,
            agent_id: 6,
            agent_by: 6,
            agent_username: 'mahaheng',
            username: 'uf20m1ts009',
            LottoTypeHead: 74,
            LottoHead: 10632,
            LottoSubHead: 0,
            LottoHeadName: 'ทดสอบหวย',
            status: 'confirm',
            status_result: 'waiting',
            bet_digit: 1,
            bet_type: 'UP',
            name_type: 'วิ่งบน',
            amount: 15,
            win_rate: 2.8,
            result_bet: null,
            reward: 0,
            bet: '2',
            sort_bet: '2',
            digit1: '2',
            digit2: null,
            digit3: null,
            startbalance: 532,
            endbalance: 512,
            cancel_at: null,
            created_at: '2024-02-05T09:07:22.060Z',
            updated_at: '2024-02-05T09:07:22.060Z',
            __v: 0,
          },
        ],
        ChangRate: [
          {
            type: 'วิ่งบน',
            NumBet: '2',
            NextRate: 2.7,
            NextAmount: 5,
            CurrentBet: 15,
            CurrentRate: 2.8,
          },
        ],
      },
      search_type: '',
      selectedType: 'ก',
      number_win: [],
      dream: [],
      thaiList: this.generateThaiAlphabet(),
      butWinTong: false,
      butWinBle: false,
      butWin3tTong: false,
      butWin3tFrontDup: false,
      butWin3tEndDup: false,
      butWin3tNoSpread: false,
    }
  },
  computed: {
    filteredDreams() {
      return this.dream.filter(d => {
        const matchType = this.selectedType
          ? d.types === this.selectedType
          : true

        const matchSearch = this.search_type
          ? d.name.includes(this.search_type)
          : true

        return matchType && matchSearch
      })
    },
    PrintAuto() {
      return this.$store.state.app.printListAuto
    },
    BaseWsUrl() {
      return this.$store.state.app.BaseWsUrl
    },
    WsMain() {
      return this.$store.state.app.WsMain
    },
    WsLimit() {
      return this.$store.state.app.WsLimit
    },
  },
  beforeDestroy() {
    clearInterval(this.Interval)
    clearInterval(this.IntervalTime)
  },
  created() {},
  async mounted() {
    // this.$refs['my-percent'].show()
    // this.$refs['my-modal'].show()
    this.getDream()
    // this.getDreamImg()
    this.ShowWallet()
    this.GetDataLotto()
    // this.CheckWsConnect()
    this.IntervalTime = setInterval(() => {
      this.ShowWalletNow()
      this.CloseTimeShow = this.CalDiffTime()
      if (this.CloseTimeShow === '0 วัน 00 : 00 : 00') {
        this.$router.push({ name: 'tanghuay-round' })
      }
    }, 500)
  },
  methods: {
    generateThaiAlphabet() {
      const startCode = 3585 // Unicode ของ 'ก'
      const endCode = 3630 // Unicode ของ 'ฮ'
      const thaiAlphabet = []
      for (let i = startCode; i <= endCode; i++) {
        thaiAlphabet.push(String.fromCharCode(i))
      }
      return thaiAlphabet
    },
    winTap(val) {
      this.winActive = val
    },
    Betting2(val) {
      if (val === 'ลบ') {
        this.number_win.pop()
      } else if (val === 'ล้างโพย') {
        this.number_win = []
      } else if (!this.number_win.includes(val)) {
        this.number_win.push(val)
      }
    },
    async getDream() {
      try {
        const { data } = await this.$http.get('dreams/list')
        // console.log(data)
        this.dream = data.data
      } catch (error) {
        console.log(error)
      }
    },
    getMydream(type) {
      this.selectedType = type
      this.search_type = ''
    },
    clearSelectedType() {
      this.selectedType = ''
    },
    showRate() {
      this.$refs['my-rate'].show()
    },
    formatCloseDateName(val) {
      if (!val) {
        return 'ปิดรับแทง'
      }
      if (val.startsWith('0 วัน')) {
        return val.replace('0 วัน', '').trim()
      }
      return val
    },
    async CheckWsConnect() {
      if (!this.WsMain) {
        await this.ConnectWS()
      }
      if (!this.WsLimit || this.WsLimit.length === 0) {
        await this.SubWsOnLimit()
      } else {
        await this.CheckWsLimit()
      }
    },
    combinedNumbers(val2, val3) {
      return [...val2, ...val3].join(', ')
    },
    ConnectWS() {
      const Token = localStorage.getItem('token')
      if (Token) {
        // const ws = Ws('wss://api.anan-cargo.com')
        // console.log(Token)
        const ws = Ws(this.BaseWsUrl)
        ws.withJwtToken(Token).connect()

        ws.on('open', () => {
          console.log('Connection established')
        })
        ws.on('error', error => {
          console.log('Connection Error', error)
        })

        ws.on('close', event => {
          console.log('Connection closed', event)
        })
        this.$store.commit('app/SET_WSMAIN', ws)
      }
    },
    async SubWsOnLimit() {
      // const UserData = JSON.parse(localStorage.getItem('userData'))
      // console.debug(this.Title.RoundID)
      const WsLimit = this.WsMain.subscribe(`roundlimit:${this.Title.RoundID}`)
      const ObjJson = {
        round_id: this.Title.RoundID,
        ws: WsLimit,
      }
      this.$store.commit('app/SET_WSLIMIT', ObjJson)
      await this.CheckWsLimit()
    },
    async CheckWsLimit() {
      // console.debug(this.WsLimit)
      const RoundWs = this.WsLimit.find(
        ele => ele.round_id === this.Title.RoundID,
      )
      // console.debug('FindRound', RoundWs)
      if (RoundWs) {
        await this.WsOnLimit(RoundWs.ws)
      } else {
        await this.SubWsOnLimit()
      }
    },
    async WsOnLimit(RoundWs) {
      RoundWs.on('open', () => {
        console.debug('CartWs established')
      })
      RoundWs.on('betinround', message => {
        console.debug('betinround Message', message)
      })
      // RoundWs.emit('mes', '1+1')
      // RoundWs.on('mes2', message => {
      //   console.debug('Cart Message', message)
      // })
      RoundWs.on('error', error => {
        console.debug('Cart Error', error)
        this.$store.commit('app/SET_WSMAIN', null)
        this.$store.commit('app/SET_WSLIMIT', [])
        this.CheckWsConnect()
      })
    },
    // Api
    async MainApi() {
      await this.ShowTitle()
      await this.GetRate(this.Title.RoundID)
      await this.GetAutoRate()
    },
    async ShowWalletNow() {
      this.MainWallet = this.$store.state.appConfig.UserData.Balance
      this.discount = this.$store.state.appConfig.UserData.Discount
    },
    async ShowWallet() {
      try {
        const params = {
          UserToken: localStorage.getItem('UserToken'),
        }
        const { data: response } = await this.$http.get(
          '/seamless/wallet/show',
          { params },
        )
        if (response && response.success === true) {
          const Main = response.WalletData
          this.MainWallet = Main.balance
          this.$store.state.appConfig.UserData = {
            Balance: Main.balance,
            Discount: response.discount,
          }
        } else {
          this.MainWallet = 0
          this.$store.state.appConfig.UserData = { Balance: 0, Discount: 0 }
        }
      } catch (err) {
        console.log(err)
      }
    },
    async GetDataLotto() {
      this.show = true
      // console.log(this.$route.params.CloseDate)
      this.NameShow = this.$route.params.LottoName
      this.Title.LottoHead = this.$route.params.LottoHead
      this.Title.RoundID = this.$route.params.round_id
      if (
        this.$route.params.LottoSubHead === 'null'
        || this.$route.params.LottoSubHead === 'undefined'
      ) {
        this.$route.params.LottoSubHead = null
      }
      this.Title.LottoSubHead = this.$route.params.LottoSubHead
      await this.MainApi()
      this.show = false
    },
    async ShowTitle() {
      const params = {
        RoundID: this.Title.RoundID,
      }
      try {
        const { data: ResData } = await this.$http.get(
          '/member/round/ShowByHeadAndSub/Buynow',
          { params },
        )
        if (ResData.success) {
          // eslint-disable-next-line no-underscore-dangle
          this.Title.LottoTypeHead = ResData.mes.LottoTypeHead
          this.Title.CloseBet = ResData.mes.StopBetTime
          this.Title.CloseDate = ResData.mes.CloseDate
          this.Title.CloseDateName = ResData.mes.CloseDateName
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'แจ้งเตือน',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'ไม่พบรอบที่เปิดรับแทง',
          },
        })
        // console.log(e)
      }
    },
    async GetRate(RoundID) {
      const params = {
        RoundID,
      }
      try {
        const { data: ResData } = await this.$http.get(
          '/member/ratebyround/showbetmore',
          { params },
        )
        if (ResData.success) {
          // console.log(ResData.mes)
          const Rate = ResData.mes.Data
          const ListBet = ResData.mes.LimitData
          if (this.UserData.currency == 'KRW') {
            this.currency_unit = 'วอน'
            this.BaseAmount = 50
            Rate.MinU3 *= this.BaseAmount
            Rate.MinD3 *= this.BaseAmount
            Rate.MinB3 *= this.BaseAmount
            Rate.MinT3 *= this.BaseAmount
            Rate.MinU2 *= this.BaseAmount
            Rate.MinD2 *= this.BaseAmount
            Rate.MinT2 *= this.BaseAmount
            Rate.MinU1 *= this.BaseAmount
            Rate.MinD1 *= this.BaseAmount

            Rate.MaxU3 *= this.BaseAmount
            Rate.MaxD3 *= this.BaseAmount
            Rate.MaxB3 *= this.BaseAmount
            Rate.MaxT3 *= this.BaseAmount
            Rate.MaxU2 *= this.BaseAmount
            Rate.MaxD2 *= this.BaseAmount
            Rate.MaxT2 *= this.BaseAmount
            Rate.MaxU1 *= this.BaseAmount
            Rate.MaxD1 *= this.BaseAmount

            Rate.BetLimitU3 *= this.BaseAmount
            Rate.BetLimitD3 *= this.BaseAmount
            Rate.BetLimitB3 *= this.BaseAmount
            Rate.BetLimitT3 *= this.BaseAmount
            Rate.BetLimitU2 *= this.BaseAmount
            Rate.BetLimitD2 *= this.BaseAmount
            Rate.BetLimitT2 *= this.BaseAmount
            Rate.BetLimitU1 *= this.BaseAmount
            Rate.BetLimitD1 *= this.BaseAmount
          }

          this.RateAll.Three.Up.Min = Rate.MinU3
          this.RateAll.Three.Down_f.Min = Rate.MinD3
          this.RateAll.Three.Down_b.Min = Rate.MinB3
          this.RateAll.Three.Tot.Min = Rate.MinT3
          this.RateAll.Two.Up.Min = Rate.MinU2
          this.RateAll.Two.Down.Min = Rate.MinD2
          this.RateAll.Two.Tot.Min = Rate.MinT2
          this.RateAll.One.Up.Min = Rate.MinU1
          this.RateAll.One.Down.Min = Rate.MinD1

          this.RateAll.Three.Up.Max = Rate.MaxU3
          this.RateAll.Three.Down_f.Max = Rate.MaxD3
          this.RateAll.Three.Down_b.Max = Rate.MaxB3
          this.RateAll.Three.Tot.Max = Rate.MaxT3
          this.RateAll.Two.Up.Max = Rate.MaxU2
          this.RateAll.Two.Down.Max = Rate.MaxD2
          this.RateAll.Two.Tot.Max = Rate.MaxT2
          this.RateAll.One.Up.Max = Rate.MaxU1
          this.RateAll.One.Down.Max = Rate.MaxD1

          this.RateAll.Three.Up.Rate = Rate.U3
          this.RateAll.Three.Down_f.Rate = Rate.D3
          this.RateAll.Three.Down_b.Rate = Rate.B3
          this.RateAll.Three.Tot.Rate = Rate.T3
          this.RateAll.Two.Up.Rate = Rate.U2
          this.RateAll.Two.Down.Rate = Rate.D2
          this.RateAll.Two.Tot.Rate = Rate.T2
          this.RateAll.One.Up.Rate = Rate.U1
          this.RateAll.One.Down.Rate = Rate.D1

          this.RateAll.Three.Up.BetLimit = Rate.BetLimitU3
          this.RateAll.Three.Down_f.BetLimit = Rate.BetLimitD3
          this.RateAll.Three.Down_b.BetLimit = Rate.BetLimitB3
          this.RateAll.Three.Tot.BetLimit = Rate.BetLimitT3
          this.RateAll.Two.Up.BetLimit = Rate.BetLimitU2
          this.RateAll.Two.Down.BetLimit = Rate.BetLimitD2
          this.RateAll.Two.Tot.BetLimit = Rate.BetLimitT2
          this.RateAll.One.Up.BetLimit = Rate.BetLimitU1
          this.RateAll.One.Down.BetLimit = Rate.BetLimitD1

          this.RateAll.Three.Up.BetList = ListBet.U3
          this.RateAll.Three.Down_f.BetList = ListBet.D3
          this.RateAll.Three.Down_b.BetList = ListBet.B3
          this.RateAll.Three.Tot.BetList = ListBet.T3
          this.RateAll.Two.Up.BetList = ListBet.U2
          this.RateAll.Two.Down.BetList = ListBet.D2
          this.RateAll.Two.Tot.BetList = ListBet.T2
          this.RateAll.One.Up.BetList = ListBet.U1
          this.RateAll.One.Down.BetList = ListBet.D1

          this.Title.CloseBetU3 = Rate.CloseBetU3.map(x => x.NumLot)
          this.Title.CloseBetD3 = Rate.CloseBetD3.map(x => x.NumLot)
          this.Title.CloseBetB3 = Rate.CloseBetB3.map(x => x.NumLot)
          this.Title.CloseBetT3 = Rate.CloseBetT3.map(x => x.NumSort)
          this.Title.CloseBetU2 = Rate.CloseBetU2.map(x => x.NumLot)
          this.Title.CloseBetD2 = Rate.CloseBetD2.map(x => x.NumLot)
          this.Title.CloseBetT2 = Rate.CloseBetT2.map(x => x.NumSort)
          this.Title.CloseBetU1 = Rate.CloseBetU1.map(x => x.NumLot)
          this.Title.CloseBetD1 = Rate.CloseBetD1.map(x => x.NumLot)
          this.Title.LimitU3 = Rate.LimitU3.sort((a, b) => a.NumLot - b.NumLot)
          this.Title.LimitD3 = Rate.LimitD3.sort((a, b) => a.NumLot - b.NumLot)
          this.Title.LimitB3 = Rate.LimitB3.sort((a, b) => a.NumLot - b.NumLot)
          this.Title.LimitT3 = Rate.LimitT3.sort((a, b) => a.NumLot - b.NumLot)
          this.Title.LimitU2 = Rate.LimitU2.sort((a, b) => a.NumLot - b.NumLot)
          this.Title.LimitD2 = Rate.LimitD2.sort((a, b) => a.NumLot - b.NumLot)
          this.Title.LimitT2 = Rate.LimitT2.sort((a, b) => a.NumLot - b.NumLot)
          this.Title.LimitU1 = Rate.LimitU1.sort((a, b) => a.NumLot - b.NumLot)
          this.Title.LimitD1 = Rate.LimitD1.sort((a, b) => a.NumLot - b.NumLot)

          this.CloseBet.CloseU3 = this.CalCloseBet(
            ListBet.U3,
            this.Title.CloseBetU3,
            Rate.BetLimitU3,
          )
          this.CloseBet.CloseD3 = this.CalCloseBet(
            ListBet.D3,
            this.Title.CloseBetD3,
            Rate.BetLimitD3,
          )
          this.CloseBet.CloseB3 = this.CalCloseBet(
            ListBet.B3,
            this.Title.CloseBetB3,
            Rate.BetLimitB3,
          )
          this.CloseBet.CloseT3 = this.CalCloseBet(
            ListBet.T3,
            this.Title.CloseBetT3,
            Rate.BetLimitT3,
          )
          this.CloseBet.CloseU2 = this.CalCloseBet(
            ListBet.U2,
            this.Title.CloseBetU2,
            Rate.BetLimitU2,
          )
          this.CloseBet.CloseD2 = this.CalCloseBet(
            ListBet.D2,
            this.Title.CloseBetD2,
            Rate.BetLimitD2,
          )
          this.CloseBet.CloseT2 = this.CalCloseBet(
            ListBet.T2,
            this.Title.CloseBetT2,
            Rate.BetLimitT2,
          )
          this.CloseBet.CloseU1 = this.CalCloseBet(
            ListBet.U1,
            this.Title.CloseBetU1,
            Rate.BetLimitU1,
          )
          this.CloseBet.CloseD1 = this.CalCloseBet(
            ListBet.D1,
            this.Title.CloseBetD1,
            Rate.BetLimitD1,
          )

          this.Title.LimitU3 = this.CalLimit(
            this.Title.LimitU3,
            this.CloseBet.CloseU3,
          )
          this.Title.LimitD3 = this.CalLimit(
            this.Title.LimitD3,
            this.CloseBet.CloseD3,
          )
          this.Title.LimitB3 = this.CalLimit(
            this.Title.LimitB3,
            this.CloseBet.CloseB3,
          )
          this.Title.LimitT3 = this.CalLimit(
            this.Title.LimitT3,
            this.CloseBet.CloseT3,
          )
          this.Title.LimitU2 = this.CalLimit(
            this.Title.LimitU2,
            this.CloseBet.CloseU2,
          )
          this.Title.LimitD2 = this.CalLimit(
            this.Title.LimitD2,
            this.CloseBet.CloseD2,
          )
          this.Title.LimitT2 = this.CalLimit(
            this.Title.LimitT2,
            this.CloseBet.CloseT2,
          )
          this.Title.LimitU1 = this.CalLimit(
            this.Title.LimitU1,
            this.CloseBet.CloseU1,
          )
          this.Title.LimitD1 = this.CalLimit(
            this.Title.LimitD1,
            this.CloseBet.CloseD1,
          )
          // console.log(this.Title)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async GetAutoRate() {
      const params = {
        LottoTypeHead: this.Title.LottoTypeHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          '/auto/ratelimit/showactive',
          { params },
        )
        if (ResData.success) {
          this.HaveAutoRate = true
          this.AutoRateData = ResData.mes
          this.Title.LimitU3 = []
          this.Title.LimitT3 = []
          this.Title.LimitU2 = []
          this.Title.LimitD2 = []
          this.Title.LimitU1 = []
          this.Title.LimitD1 = []
          if (this.UserData.currency == 'KRW') {
            this.BaseAmount = 50
            this.AutoRateData.MaxAmountU3 *= this.BaseAmount
            this.AutoRateData.MaxAmountT3 *= this.BaseAmount
            this.AutoRateData.MaxAmountU2 *= this.BaseAmount
            this.AutoRateData.MaxAmountD2 *= this.BaseAmount
            this.AutoRateData.MaxAmountU1 *= this.BaseAmount
            this.AutoRateData.MaxAmountD1 *= this.BaseAmount
          }
          this.Title.LimitU3 = this.CheckAutoLimit(
            this.RateAll.Three.Up,
            this.AutoRateData.MaxAmountU3,
            this.AutoRateData.StepU3,
            this.AutoRateData.StepRateU3,
            this.Title.LimitU3,
          )
          this.Title.LimitT3 = this.CheckAutoLimit(
            this.RateAll.Three.Tot,
            this.AutoRateData.MaxAmountT3,
            this.AutoRateData.StepT3,
            this.AutoRateData.StepRateT3,
            this.Title.LimitT3,
          )
          this.Title.LimitU2 = this.CheckAutoLimit(
            this.RateAll.Two.Up,
            this.AutoRateData.MaxAmountU2,
            this.AutoRateData.StepU2,
            this.AutoRateData.StepRateU2,
            this.Title.LimitU2,
          )
          this.Title.LimitD2 = this.CheckAutoLimit(
            this.RateAll.Two.Down,
            this.AutoRateData.MaxAmountD2,
            this.AutoRateData.StepD2,
            this.AutoRateData.StepRateD2,
            this.Title.LimitD2,
          )
          this.Title.LimitU1 = this.CheckAutoLimit(
            this.RateAll.One.Up,
            this.AutoRateData.MaxAmountU1,
            this.AutoRateData.StepU1,
            this.AutoRateData.StepRateU1,
            this.Title.LimitU1,
          )
          this.Title.LimitD1 = this.CheckAutoLimit(
            this.RateAll.One.Down,
            this.AutoRateData.MaxAmountD1,
            this.AutoRateData.StepD1,
            this.AutoRateData.StepRateD1,
            this.Title.LimitD1,
          )
          if (this.Title.LimitU3.length > 0) {
            this.Title.LimitU3 = this.CalLimit(
              this.Title.LimitU3,
              this.CloseBet.CloseU3,
            )
          }
          if (this.Title.LimitT3.length > 0) {
            this.Title.LimitT3 = this.CalLimit(
              this.Title.LimitT3,
              this.CloseBet.CloseT3,
            )
          }
          if (this.Title.LimitU2.length > 0) {
            this.Title.LimitU2 = this.CalLimit(
              this.Title.LimitU2,
              this.CloseBet.CloseU2,
            )
          }
          if (this.Title.LimitD2.length > 0) {
            this.Title.LimitD2 = this.CalLimit(
              this.Title.LimitD2,
              this.CloseBet.CloseD2,
            )
          }
          if (this.Title.LimitU1.length > 0) {
            this.Title.LimitU1 = this.CalLimit(
              this.Title.LimitU1,
              this.CloseBet.CloseU1,
            )
          }
          if (this.Title.LimitD1.length > 0) {
            this.Title.LimitD1 = this.CalLimit(
              this.Title.LimitD1,
              this.CloseBet.CloseD1,
            )
          }
        }
        // console.debug('GetAutoRate', this.Title.LimitU2)
      } catch (e) {
        // console.log(e)
      }
    },
    CheckAutoLimit(RateAll, MaxAmount, Step, StepRate, Limit) {
      // eslint-disable-next-line
      for (const item of RateAll.BetList) {
        // console.log(item._id)
        const res = this.calculateRate(
          item.totalAmount,
          RateAll.Rate,
          MaxAmount,
          Step,
          StepRate,
        )

        // console.log(res)
        if (res.limit) {
          const Obj = {
            // eslint-disable-next-line no-underscore-dangle
            NumLot: item._id,
            Rate: res.rate,
          }
          Limit.push(Obj)
        }
      }
      Limit.sort((a, b) => a.NumLot - b.NumLot)
      return Limit
    },
    calculateRate(TotalAmount, baseRate, MaxAmount, Step, rateSteps) {
      // console.log(TotalAmount, baseRate, MaxAmount, Step, rateSteps)
      let res = { limit: false, rate: baseRate }
      let average = MaxAmount / Step
      if (this.isDecimal(average)) {
        average = this.toInteger(average)
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < Step; i++) {
        if (TotalAmount < average * (i + 1)) {
          if (i > 0 || TotalAmount === average) {
            res = { limit: true, rate: rateSteps[i] }
          }
          break
        }
      }

      return res
    },
    async SummitBet() {
      // console.log(this.items)
      const CheckPrice = this.items.some(el => el.price === '')
      if (CheckPrice === false) {
        this.CheckSubmit = false
        if (this.SumAmount > this.MainWallet) {
          // this.SwalMes(
          //   'warning',
          //   'จำนวนเงินไม่เพียงพอ !!',
          //   'ยอดเงินของท่านไม่เพียงพอกรุณาฝากเงินก่อนค่ะ',
          // )
          this.$refs['my-warning'].show()
          // this.items.splice(this.items.length - 1, 1)
        } else {
          this.show = true

          const Obj = {
            UserToken: localStorage.getItem('UserToken'),
            SumAmount: this.SumAmount,
            discount: this.discount,
            discount_remark: this.discount_member,
            UserData: null,
            remark: this.remark,
            OBJ: this.items,
          }
          // console.log(Obj)
          try {
            const { data: response } = await this.$http.post(
              '/seamless/deduct',
              Obj,
            )
            // const response = this.apiFAKE
            if (response) {
              this.items = []
              this.PickThreeArr = this.GenNum(1000, 3)
              this.PickTwoArr = this.GenNum(100, 2)
              this.PickOneArr = this.GenNum(10, 1)
              this.CheckSubmit = true
              this.confirm = false
              this.remark = null
              this.show = false
              this.ShowWallet()
              this.MainApi()
              // await this.showModal(response)
              if (response.success === true) {
                const FristBet = response.Bet[0]
                this.Slip.SumAmount = response.WalletData.Balance - response.WalletData.balance
                this.Slip.date = momenttz(FristBet.created_at)
                  .tz('Asia/Bangkok')
                  .format('DD/MM/YYYY')
                this.Slip.time = momenttz(FristBet.created_at)
                  .tz('Asia/Bangkok')
                  .format('HH:mm')
                this.Slip.listID = FristBet.list_id
                this.Slip.count = response.Bet.length
                this.Slip.LottoHeadName = FristBet.LottoHeadName
                this.$refs['my-modal'].show()
                if (this.PrintAuto) {
                  window.open(
                    `https://api.hapi-lot.com/api/slip/listid?ListID=${FristBet.list_id}`,
                  )
                }
              } else if (response.store_bet) {
                const FristBet = response.Bet[0]
                this.Slip.SumAmount = response.WalletData.Balance - response.WalletData.balance
                this.Slip.date = momenttz(FristBet.created_at)
                  .tz('Asia/Bangkok')
                  .format('DD/MM/YYYY')
                this.Slip.time = momenttz(FristBet.created_at)
                  .tz('Asia/Bangkok')
                  .format('HH:mm')
                this.Slip.listID = FristBet.list_id
                this.Slip.count = response.Bet.length
                this.Slip.LottoHeadName = FristBet.LottoHeadName
                this.LimitRes = response.LimitRes.length > 0 ? response.LimitRes : 0
                this.ChangRate = response.ChangRate.length > 0 ? response.ChangRate : 0
                this.showAlert()
              } else {
                this.LimitRes = response.LimitRes.length > 0 ? response.LimitRes : 0
                this.$refs['modal-limit'].show()
              }
            }
          } catch (err) {
            if (err.response.data.Mes === 'ปรับอัตราจ่าย') {
              this.rechangeRate()
            } else {
              this.$refs['modal-price'].show()
              this.mes_err = err.response.data.Mes
            }
            this.show = false
            this.confirm = false
            this.CheckSubmit = true
          }
        }
      }
    },
    async rechangeRate() {
      this.ShowWallet()
      await this.MainApi()
      const mockItem = [...this.items]
      // console.debug(mockItem)
      this.items = []
      // console.debug('rechangeRate', this.Title.LimitU2)
      mockItem.forEach(item => {
        this.PushItems(
          item.num_lot,
          item.num_type,
          item.bet_digit,
          item.bet_type,
          item.price,
        )
      })
    },
    toggleShowNumber() {
      this.isShowNumber = !this.isShowNumber
    },
    TogType(Ele, Num) {
      if (Num === 3) {
        if (Ele === 'up') {
          this.selectNumbet3U = !this.selectNumbet3U
        }
        if (Ele === 'tot') {
          this.selectNumbet3T = !this.selectNumbet3T
          this.selectNumbet3D = false
        }
        if (Ele === 'flip') {
          this.selectNumbet3D = !this.selectNumbet3D
          this.selectNumbet3T = false
        }
        // if (!this.Mode) {
        this.selectNumbet2U = false
        this.selectNumbet2D = false
        this.selectNumbet2T = false
        // }
        this.selectNumbet1U = false
        this.selectNumbet1D = false
        this.selectNumbet3U2U2D = false
        this.selectNumbet3U2U2DFlip = false
      } else if (Num === 2) {
        if (Ele === 'up') {
          this.selectNumbet2U = !this.selectNumbet2U
        }
        if (Ele === 'down') {
          this.selectNumbet2D = !this.selectNumbet2D
        }
        if (Ele === 'flip') {
          this.selectNumbet2T = !this.selectNumbet2T
        }
        // if (!this.Mode) {
        this.selectNumbet3U = false
        this.selectNumbet3D = false
        this.selectNumbet3T = false
        // }
        this.selectNumbet1U = false
        this.selectNumbet1D = false
        this.selectNumbet3U2U2D = false
        this.selectNumbet3U2U2DFlip = false
      } else if (Num === 1) {
        if (Ele === 'up') {
          this.selectNumbet1U = !this.selectNumbet1U
        }
        if (Ele === 'down') {
          this.selectNumbet1D = !this.selectNumbet1D
        }
        this.selectNumbet3U = false
        this.selectNumbet3D = false
        this.selectNumbet3T = false
        this.selectNumbet2U = false
        this.selectNumbet2D = false
        this.selectNumbet2T = false
        this.selectNumbet3U2U2D = false
        this.selectNumbet3U2U2DFlip = false
      } else if (Num === 0) {
        if (Ele === 'all') {
          this.selectNumbet3U2U2D = !this.selectNumbet3U2U2D
        }
        if (Ele === 'flip') {
          this.selectNumbet3U2U2DFlip = !this.selectNumbet3U2U2DFlip
        }
        this.selectNumbet3U = false
        this.selectNumbet3D = false
        this.selectNumbet3T = false
        this.selectNumbet2U = false
        this.selectNumbet2D = false
        this.selectNumbet2T = false
        this.selectNumbet1U = false
        this.selectNumbet1D = false
      }
    },
    TogThreeType(Ele, Len) {
      if (Len === 3) {
        this.SubThree0 = false
        this.SubThree1 = false
        this.SubThree2 = false
        this.SubThree3 = false
        this.SubThree4 = false
        this.SubThree5 = false
        this.SubThree6 = false
        this.SubThree7 = false
        this.SubThree8 = false
        this.SubThree9 = false
        this.PickThree = Ele
        if (Ele === 0) {
          this.SubThree0 = true
        } else if (Ele === 100) {
          this.SubThree1 = true
        } else if (Ele === 200) {
          this.SubThree2 = true
        } else if (Ele === 300) {
          this.SubThree3 = true
        } else if (Ele === 400) {
          this.SubThree4 = true
        } else if (Ele === 500) {
          this.SubThree5 = true
        } else if (Ele === 600) {
          this.SubThree6 = true
        } else if (Ele === 700) {
          this.SubThree7 = true
        } else if (Ele === 800) {
          this.SubThree8 = true
        } else if (Ele === 900) {
          this.SubThree9 = true
        }
      }
    },
    PickNum(item, Arr) {
      if (this.selectNumbet3D) {
        const Switch3Arr = this.SwitchNum3(item.Num)
        this.CheckPickNum(Switch3Arr, Arr)
      } else if (this.selectNumbet2T) {
        const Switch2Arr = this.SwitchNum2(item.Num)
        this.CheckPickNum(Switch2Arr, Arr)
      } else {
        this.PushType(item)
      }
    },
    CheckPickNum(SwipArr, NumArr, ForCheck = true) {
      SwipArr.forEach(ele => {
        const IndexNum = NumArr.findIndex(({ Num }) => Num === ele)
        if (IndexNum >= 0) {
          this.PushType(NumArr[IndexNum], ForCheck)
        }
      })
    },
    async PushType(Arr, ForCheck = true) {
      if (this.selectNumbet3U) {
        // eslint-disable-next-line no-param-reassign
        Arr.U3 = !Arr.U3
        if (Arr.U3) {
          await this.PushItems(Arr.Num, '3ตัวบน', 3, 'UP', this.BaseAmount)
        } else {
          this.RemovePush(this.items, Arr.Num, '3ตัวบน')
        }
      }
      if (this.selectNumbet3T) {
        // eslint-disable-next-line no-param-reassign
        Arr.T3 = !Arr.T3
        if (Arr.T3) {
          await this.PushItems(Arr.Num, '3ตัวโต๊ด', 3, 'TOT', this.BaseAmount)
        } else {
          this.RemovePush(this.items, Arr.Num, '3ตัวโต๊ด')
        }
      }
      if (this.selectNumbet2U) {
        if (ForCheck) {
          // eslint-disable-next-line no-param-reassign
          Arr.U2 = !Arr.U2
          if (Arr.U2) {
            await this.PushItems(Arr.Num, '2ตัวบน', 2, 'UP', this.BaseAmount)
          } else {
            this.RemovePush(this.items, Arr.Num, '2ตัวบน')
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          Arr.U2 = true
          await this.PushItems(Arr.Num, '2ตัวบน', 2, 'UP', this.BaseAmount)
        }
      }
      if (this.selectNumbet2D) {
        if (ForCheck) {
          // eslint-disable-next-line no-param-reassign
          Arr.D2 = !Arr.D2
          if (Arr.D2) {
            await this.PushItems(
              Arr.Num,
              '2ตัวล่าง',
              2,
              'DOWN',
              this.BaseAmount,
            )
          } else {
            this.RemovePush(this.items, Arr.Num, '2ตัวล่าง')
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          Arr.D2 = true
          await this.PushItems(Arr.Num, '2ตัวล่าง', 2, 'DOWN', this.BaseAmount)
        }
      }
      if (this.selectNumbet1U) {
        // eslint-disable-next-line no-param-reassign
        Arr.U1 = !Arr.U1
        if (Arr.U1) {
          await this.PushItems(Arr.Num, 'วิ่งบน', 1, 'UP', this.BaseAmount)
        } else {
          this.RemovePush(this.items, Arr.Num, 'วิ่งบน')
        }
      }
      if (this.selectNumbet1D) {
        // eslint-disable-next-line no-param-reassign
        Arr.D1 = !Arr.D1
        if (Arr.D1) {
          await this.PushItems(Arr.Num, 'วิ่งล่าง', 1, 'DOWN', this.BaseAmount)
        } else {
          this.RemovePush(this.items, Arr.Num, 'วิ่งล่าง')
        }
      }
    },
    ActiveBet(Ele) {
      if (this.selectNumbet3U && this.selectNumbet3T) {
        return Ele.U3 || Ele.T3
      }
      if (this.selectNumbet3U) {
        return Ele.U3
      }
      if (this.selectNumbet3T) {
        return Ele.T3
      }
      if (this.selectNumbet2U && this.selectNumbet2D) {
        return Ele.U2 || Ele.D2
      }
      if (this.selectNumbet2U) {
        return Ele.U2
      }
      if (this.selectNumbet2D) {
        return Ele.D2
      }
      if (this.selectNumbet1U && this.selectNumbet1D) {
        return Ele.U1 || Ele.D1
      }
      if (this.selectNumbet1U) {
        return Ele.U1
      }
      if (this.selectNumbet1D) {
        return Ele.D1
      }
      return false
    },
    CheckTog(Type, Arr, Value) {
      let SwipChang = []
      let IndexNum = false
      if (Type === 'nine') {
        SwipChang = this.Swipe19(Value)
      } else if (Type === 'front') {
        SwipChang = this.SwipeFront(Value)
      } else if (Type === 'back') {
        SwipChang = this.SwipeBack(Value)
      }
      if (this.selectNumbet2U) {
        IndexNum = Arr.findIndex(
          ({ Num, U2 }) => SwipChang.includes(Num) && !U2,
        )
      } else if (this.selectNumbet2D) {
        IndexNum = Arr.findIndex(
          ({ Num, D2 }) => SwipChang.includes(Num) && !D2,
        )
      }
      if (IndexNum < 0) {
        return { SwipChang, status: true }
      }
      return { SwipChang, status: false }
    },
    RemovePush(Arr, Num, NumType) {
      // eslint-disable-next-line camelcase
      const IndexNum = Arr.findIndex(
        // eslint-disable-next-line camelcase
        ({ num_lot, num_type }) => num_lot === Num && num_type === NumType,
      )
      if (IndexNum >= 0) {
        Arr.splice(IndexNum, 1)
      }
    },
    DisableInList(BetDigit, BetType, Numlot) {
      let Arr = []
      let Type = ''
      if (BetDigit === 3) {
        Arr = this.PickThreeArr
        // eslint-disable-next-line
        Type = BetType === "UP" ? "U3" : BetType === "TOT" ? "T3" : "D3";
      } else if (BetDigit === 2) {
        Arr = this.PickTwoArr
        Type = BetType === 'UP' ? 'U2' : 'D2'
      } else if (BetDigit === 1) {
        Arr = this.PickOneArr
        Type = BetType === 'UP' ? 'U1' : 'D1'
      }
      const IndexNum = Arr.findIndex(({ Num }) => Num === Numlot)
      if (IndexNum >= 0) {
        // eslint-disable-next-line no-param-reassign
        Arr[IndexNum][Type] = false
      }
    },
    EnableInList(Arr, Type, Numlot) {
      const IndexNum = Arr.findIndex(({ Num }) => Num === Numlot)
      if (IndexNum >= 0) {
        // eslint-disable-next-line no-param-reassign
        Arr[IndexNum][Type] = true
      }
    },
    CalEnable(BetDigit, BetType, NumBet) {
      if (BetDigit === 3) {
        if (BetType === 'UP') {
          this.EnableInList(this.PickThreeArr, 'U3', NumBet)
        } else if (BetType === 'TOT') {
          this.EnableInList(this.PickThreeArr, 'T3', NumBet)
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          this.EnableInList(this.PickTwoArr, 'U2', NumBet)
        } else if (BetType === 'DOWN') {
          this.EnableInList(this.PickTwoArr, 'D2', NumBet)
        }
      } else if (BetDigit === 1) {
        if (BetType === 'UP') {
          this.EnableInList(this.PickOneArr, 'U1', NumBet)
        } else if (BetType === 'DOWN') {
          this.EnableInList(this.PickOneArr, 'D1', NumBet)
        }
      }
    },
    async CompleteNumBet() {
      if (this.selectNumbet3U && this.selectNumbet3T) {
        await this.PushItems(this.NumBet, '3ตัวบน', 3, 'UP', this.BaseAmount)
        await this.PushItems(
          this.NumBet,
          '3ตัวโต๊ด',
          3,
          'TOT',
          this.BaseAmount,
        )
      } else if (this.selectNumbet3U && this.selectNumbet3D) {
        const Switch3Arr = this.SwitchNum3(this.NumBet)
        await Switch3Arr.forEach(ele => {
          this.PushItems(ele, '3ตัวบน', 3, 'UP', this.BaseAmount)
        })
      } else if (this.selectNumbet3U) {
        await this.PushItems(this.NumBet, '3ตัวบน', 3, 'UP', this.BaseAmount)
      } else if (this.selectNumbet3T) {
        await this.PushItems(
          this.NumBet,
          '3ตัวโต๊ด',
          3,
          'TOT',
          this.BaseAmount,
        )
      }
      if (this.selectNumbet2U || this.selectNumbet2D) {
        let Num = this.NumBet
        if (this.NumBet.length > 2) {
          Num = this.NumBet.slice(1, 3)
        }
        if (this.selectNumbet2U) {
          if (this.TwoPushSubType.NineDoor) {
            const Res = this.Swipe19(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวบน', 2, 'UP', this.BaseAmount)
            })
          } else if (this.TwoPushSubType.RootFront) {
            const Res = this.SwipeFront(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวบน', 2, 'UP', this.BaseAmount)
            })
          } else if (this.TwoPushSubType.RootBack) {
            const Res = this.SwipeBack(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวบน', 2, 'UP', this.BaseAmount)
            })
          } else if (this.selectNumbet2T) {
            const Res = this.SwitchNum2(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวบน', 2, 'UP', this.BaseAmount)
            })
          } else {
            this.PushItems(Num, '2ตัวบน', 2, 'UP', this.BaseAmount)
          }
        }
        if (this.selectNumbet2D) {
          if (this.TwoPushSubType.NineDoor) {
            const Res = this.Swipe19(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.BaseAmount)
            })
          } else if (this.TwoPushSubType.RootFront) {
            const Res = this.SwipeFront(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.BaseAmount)
            })
          } else if (this.TwoPushSubType.RootBack) {
            const Res = this.SwipeBack(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.BaseAmount)
            })
          } else if (this.selectNumbet2T) {
            const Res = this.SwitchNum2(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.BaseAmount)
            })
          } else {
            this.PushItems(Num, '2ตัวล่าง', 2, 'DOWN', this.BaseAmount)
          }
        }
      }
      if (this.selectNumbet1U || this.selectNumbet1D) {
        if (this.selectNumbet1U) {
          await this.PushItems(this.NumBet, 'วิ่งบน', 1, 'UP', this.BaseAmount)
        }
        if (this.selectNumbet1D) {
          await this.PushItems(
            this.NumBet,
            'วิ่งล่าง',
            1,
            'DOWN',
            this.BaseAmount,
          )
        }
      }
      this.ClearAfterBet()
    },
    async PreSubmit() {
      let ValiU3 = true
      let ValiT3 = true
      let ValiU2 = true
      let ValiD2 = true
      let ValiU1 = true
      let ValiD1 = true
      if (this.items.filter(ele => ele.num_type === '3ตัวบน').length > 0) {
        ValiU3 = await this.$refs.BetItemsRulesU3.validate()
      }
      if (this.items.filter(ele => ele.num_type === '3ตัวโต๊ด').length > 0) {
        ValiT3 = await this.$refs.BetItemsRulesT3.validate()
      }
      if (this.items.filter(ele => ele.num_type === '2ตัวบน').length > 0) {
        ValiU2 = await this.$refs.BetItemsRulesU2.validate()
      }
      if (this.items.filter(ele => ele.num_type === '2ตัวล่าง').length > 0) {
        ValiD2 = await this.$refs.BetItemsRulesD2.validate()
      }
      if (this.items.filter(ele => ele.num_type === 'วิ่งบน').length > 0) {
        ValiU1 = await this.$refs.BetItemsRulesU1.validate()
      }
      if (this.items.filter(ele => ele.num_type === 'วิ่งล่าง').length > 0) {
        ValiD1 = await this.$refs.BetItemsRulesD1.validate()
      }
      if (ValiU3 && ValiT3 && ValiU2 && ValiD2 && ValiU1 && ValiD1) {
        const CheckPrice = this.items.some(el => el.price === '')
        if (CheckPrice === false) {
          this.CheckSubmit = false
          if (this.SumAmount > this.MainWallet) {
            // this.SwalMes(
            //   'warning',
            //   'จำนวนเงินไม่เพียงพอ !!',
            //   'ยอดเงินของท่านไม่เพียงพอกรุณาฝากเงินก่อนค่ะ',
            // )
            this.$refs['my-warning'].show()
            // this.items.splice(this.items.length - 1, 1)
          } else {
            this.confirm = true
          }
        }
      }
    },

    CalDiffTime() {
      const DateRound = momenttz(this.$route.params.CloseDate).tz(
        'Asia/Bangkok',
      )
      const DateNow = momenttz().tz('Asia/Bangkok')
      const Seconds = DateRound.diff(DateNow, 'seconds')

      const Day = Math.floor(Seconds / 86400)
      const Hours = Math.floor((Seconds % 86400) / 3600)
      const Minutes = Math.floor((Seconds % 3600) / 60)
      const numseconds = Math.floor((Seconds % 3600) % 60)
      if (Day >= 0) {
        return `${Day} วัน ${Hours < 10 ? `0${Hours}` : Hours} : ${
          Minutes < 10 ? `0${Minutes}` : Minutes
        } : ${numseconds < 10 ? `0${numseconds}` : numseconds}`
      }
      return null
    },
    async showRule() {
      try {
        this.show = true
        const params = {
          ID: this.Title.LottoTypeHead,
        }
        const { data: ResData } = await this.$http.get(
          '/company/lottotype/get_rules',
          { params },
        )
        this.rulesText = ResData.rules
        this.show = false
        this.$refs['my-rule'].show()
      } catch (e) {
        console.log(e)
        this.show = false
      }
    },
    AddPriceBase(val) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= this.items.length - 1; i++) {
        this.items[i].price = val
      }
    },
    handleOnComplete() {
      if (this.NumBet.length === this.CalMaxLength()) {
        setTimeout(() => this.CompleteNumBet(), 100)
      }
    },
    Betting(val) {
      if (this.NumBet.length < this.CalMaxLength()) {
        this.NumBet += val
      }
      if (this.NumBet.length === this.CalMaxLength()) {
        setTimeout(() => this.CompleteNumBet(), 200)
        // console.log(this.items)
      }
    },
    showAlertHide() {
      this.$refs['modal-alert'].hide()
    },
    // showAlert(CloseBet) {
    showAlert() {
      // this.CloseBetModal = CloseBet
      this.$refs['modal-alert'].show()
    },
    async PushItems(NumBet, NumType, BetDigit, BetType, Price) {
      // console.debug(NumBet, NumType, BetDigit, BetType, Price)

      // eslint-disable-next-line
      new Promise((resolve, reject) => {
        const { Rate, RateLimit } = this.CalRate(NumBet, BetDigit, BetType)
        let NumSort = null
        let CheckDup = null
        if (BetType === 'TOT') {
          const NumArr = NumBet.split('')
          NumArr.sort()
          NumSort = NumArr.join('')
          CheckDup = this.items.findIndex(
            obj => obj.NumSort === NumSort
              && obj.bet_digit === BetDigit
              && obj.bet_type === BetType,
          )
        } else {
          CheckDup = this.items.findIndex(
            obj => obj.num_lot === NumBet
              && obj.bet_digit === BetDigit
              && obj.bet_type === BetType,
          )
        }
        if (CheckDup >= 0) {
          const CheckLimit = this.CheckBetLimit(
            NumBet,
            BetDigit,
            BetType,
            Number(Price) + Number(this.items[CheckDup].price),
          )
          if (CheckLimit.status === true) {
            this.items[CheckDup].price = Number(Price) + Number(this.items[CheckDup].price)
          } else if (CheckLimit.amount <= 0) {
            this.SwalMes('error', 'ปิดรับแทง', `${NumType} หมายเลข ${NumBet}`)
          } else {
            this.SwalMes(
              'error',
              'ไม่สำเร็จ !',
              `${NumType} หมายเลข ${NumBet} รับแทงไม่เกิน ${this.Commas(
                CheckLimit.amount,
              )} ${this.currency_unit}`,
            )
          }
        } else {
          const CheckLimit = this.CheckBetLimit(
            NumBet,
            BetDigit,
            BetType,
            Number(Price),
          )
          if (CheckLimit.status === true) {
            if (RateLimit) {
              this.$refs['my-percent'].show()
              this.n_type = NumType
              this.n_bet = NumBet
              this.n_rate = Rate
              // this.mes_warning = `${NumType} หมายเลข ${NumBet} จ่าย ${Rate} ${this.currency_unit}`
              // this.SwalMes(
              //   'info',
              //   'หมายเลขจ่ายตาม %',
              //   `${NumType} หมายเลข ${NumBet} จ่าย ${Rate} ${this.currency_unit}`,
              // )
            }
            // eslint-disable-next-line no-shadow
            const ObjItems = {
              num_lot: NumBet,
              num_type: NumType,
              bet_digit: BetDigit, // 1, 2 ,3
              bet_type: BetType, // TOT , UP , DOWN
              price: Price,
              round_id: this.Title.RoundID,
              LottoTypeHead: this.Title.LottoTypeHead,
              LottoHead: this.Title.LottoHead,
              LottoSubHead: this.Title.LottoSubHead
                ? this.Title.LottoSubHead
                : null,
              win_rate: Rate,
              NumSort,
              RateLimit,
            }
            this.items.push(ObjItems)
            const customNumtype = [
              '3ตัวบน',
              '3ตัวโต๊ด',
              '2ตัวบน',
              '2ตัวล่าง',
              'วิ่งบน',
              'วิ่งล่าง',
            ]
            this.items = this.items.sort(
              (a, b) => customNumtype.indexOf(a.num_type)
                - customNumtype.indexOf(b.num_type),
            )
            this.CalEnable(BetDigit, BetType, NumBet)
            resolve()
          } else if (CheckLimit.amount === 0) {
            this.SwalMes('error', 'ปิดรับแทง', `${NumType} หมายเลข ${NumBet}`)
            this.DisableInList(BetDigit, BetType, NumBet)
            reject()
          } else {
            this.SwalMes(
              'error',
              'ไม่สำเร็จ !',
              `${NumType} หมายเลข ${NumBet} รับแทงไม่เกิน ${this.Commas(
                CheckLimit.amount,
              )} ${this.currency_unit}`,
            )
            reject()
          }
        }
      })
    },
    CalCloseBet(Arr, CloseList, BetLimit) {
      // console.log(Arr, CloseList, BetLimit)
      let arrArr = Arr.filter(ele => ele.totalAmount >= BetLimit)
      // eslint-disable-next-line no-underscore-dangle
      arrArr = arrArr.map(x => x._id)
      if (CloseList.length > 0) {
        arrArr.push(...CloseList)
      }
      return arrArr.sort()
    },
    CalRate(NumBet, BetDigit, BetType) {
      let Rate = 0
      let IndexLimit = null
      let RateLimit = false
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Down.Rate
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Down.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumSort }) => NumSort === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Tot.Rate
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Up.Rate
          }
        } else if (BetType === 'DOWN_F') {
          IndexLimit = this.Title.LimitD3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Down_f.Rate
          }
        } else if (BetType === 'DOWN_B') {
          IndexLimit = this.Title.LimitB3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitB3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Down_b.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumLot }) => NumLot === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Tot.Rate
          }
        }
      }
      if (IndexLimit >= 0) {
        RateLimit = true
      }
      return { Rate, RateLimit }
    },
    CalLimit(Limit, CloseBet) {
      return Limit.filter(val => !CloseBet.includes(val.NumLot))
    },
    ClearAfterBet() {
      this.NumBet = ''
      // this.AmountBet = 1
      // this.BugOtp = false
      // // eslint-disable-next-line no-return-assign
      // setTimeout(() => (this.BugOtp = true), 10)
    },
    SelctTwoTool(Val) {
      if (this.selectNumbet2U && this.selectNumbet2D) {
        this.SelectBetType2(Val, '2ตัวบน', 'UP')
        this.SelectBetType2(Val, '2ตัวล่าง', 'DOWN')
      } else if (this.selectNumbet2U) {
        this.SelectBetType2(Val, '2ตัวบน', 'UP')
      } else if (this.selectNumbet2D) {
        this.SelectBetType2(Val, '2ตัวล่าง', 'DOWN')
      }
    },
    async SelectBetType2(Val, NumType, NumTypePrefix) {
      if (Val === 'สองตัวคี่') {
        const Res = this.SwipeOdd()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
      } else if (Val === 'สองตัวคู่') {
        const Res = this.SwipeEven()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
      } else if (Val === 'สองตัวสูง') {
        const Res = this.SwipeHigh()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
      } else if (Val === 'สองตัวต่ำ') {
        const Res = this.SwipeLow()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
      } else if (Val === 'เลขเบิ้ล') {
        const Res = this.SwipeDobble()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
      }
    },
    TestPrice(Price, Min, Max) {
      if (Price < Min || Price > Max) {
        return true
      }
      return false
    },
    CheckAmount(Val, Price, Index, NumBet = null) {
      const MinMax = { Min: 0, Max: 0 }
      let BetDigit = 3
      let BetType = 'UP'
      if (Val === '3ตัวบน') {
        MinMax.Min = this.RateAll.Three.Up.Min
        MinMax.Max = this.RateAll.Three.Up.Max
      } else if (Val === '3ตัวหน้า') {
        MinMax.Min = this.RateAll.Three.Down_f.Min
        MinMax.Max = this.RateAll.Three.Down_f.Max
        BetType = 'DOWN_F'
      } else if (Val === '3ตัวท้าย') {
        MinMax.Min = this.RateAll.Three.Down_b.Min
        MinMax.Max = this.RateAll.Three.Down_b.Max
        BetType = 'DOWN_B'
      } else if (Val === '3ตัวโต๊ด') {
        MinMax.Min = this.RateAll.Three.Tot.Min
        MinMax.Max = this.RateAll.Three.Tot.Max
        BetType = 'TOT'
      } else if (Val === '2ตัวบน') {
        MinMax.Min = this.RateAll.Two.Up.Min
        MinMax.Max = this.RateAll.Two.Up.Max
        BetDigit = 2
        BetType = 'UP'
      } else if (Val === '2ตัวล่าง') {
        MinMax.Min = this.RateAll.Two.Down.Min
        MinMax.Max = this.RateAll.Two.Down.Max
        BetDigit = 2
        BetType = 'DOWN'
      } else if (Val === '2ตัวโต๊ด(บน)') {
        MinMax.Min = this.RateAll.Two.Tot.Min
        MinMax.Max = this.RateAll.Two.Tot.Max
        BetDigit = 2
        BetType = 'TOT'
      } else if (Val === 'วิ่งบน') {
        MinMax.Min = this.RateAll.One.Up.Min
        MinMax.Max = this.RateAll.One.Up.Max
        BetDigit = 1
        BetType = 'UP'
      } else if (Val === 'วิ่งล่าง') {
        MinMax.Min = this.RateAll.One.Down.Min
        MinMax.Max = this.RateAll.One.Down.Max
        BetDigit = 1
        BetType = 'DOWN'
      }
      if (Price) {
        if (Price < MinMax.Min) {
          this.items[Index].price = MinMax.Min
        } else if (Price > MinMax.Max) {
          this.items[Index].price = MinMax.Max
        }
        const CheckLimit = this.CheckBetLimit(
          NumBet,
          BetDigit,
          BetType,
          Number(Price),
        )
        if (CheckLimit.status === false) {
          this.items[Index].price = CheckLimit.amount > 0 ? CheckLimit.amount : 0
        }
      }
      return MinMax
    },
    CheckBetLimit(NumBet, BetDigit, BetType, Price) {
      let TotalAmount = Price
      let SumAmount = 0
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT2.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN_F') {
          const CloseIn = this.CloseBet.CloseD3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Down_f.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Down_f.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Down_f.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN_B') {
          const CloseIn = this.CloseBet.CloseB3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Down_b.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Down_b.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Down_b.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT3.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      }
      return { status: true }
    },
    GenNum(value, Len) {
      const Arr = []
      // eslint-disable-next-line
      for (let i = 0; i < value; i++) {
        Arr.push({
          Num: this.addLeadingZeros(i, Len),
          Index: false,
          U3: false,
          T3: false,
          U2: false,
          D2: false,
          U1: false,
          D1: false,
        })
      }
      return Arr
    },
    addLeadingZeros(num, totalLength) {
      return String(num).padStart(totalLength, '0')
    },
    // 3Digit
    SwitchNum3(textnum) {
      const ReArr = []
      const num1 = textnum.substr(0, 1)
      const num2 = textnum.substr(1, 1)
      const num3 = textnum.substr(2, 1)
      if (num1 === num2 || num1 === num3 || num2 === num3) {
        if (num1 === num2) {
          ReArr.push(
            num1 + num1 + num3,
            num1 + num3 + num1,
            num3 + num1 + num1,
          )
        } else if (num1 === num3) {
          ReArr.push(
            num1 + num2 + num1,
            num1 + num1 + num2,
            num2 + num1 + num1,
          )
        } else {
          ReArr.push(
            num1 + num2 + num2,
            num2 + num2 + num1,
            num2 + num1 + num2,
          )
        }
      } else {
        ReArr.push(
          num1 + num2 + num3,
          num1 + num3 + num2,
          num2 + num1 + num3,
          num2 + num3 + num1,
          num3 + num1 + num2,
          num3 + num2 + num1,
        )
      }
      return ReArr
    },
    // 2Digit
    SwitchNum2(textnum) {
      const ReArr = []
      const Val = String(textnum)
      const num1 = Val.substr(0, 1)
      const num2 = Val.substr(1, 1)
      if (num1 === num2) {
        ReArr.push(num1 + num2)
      } else {
        ReArr.push(num1 + num2, num2 + num1)
      }
      return ReArr
    },
    SwipeFront(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = String(Val) + i
        ReArr.push(R1)
      }
      return ReArr
    },
    SwipeBack(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      return ReArr
    },
    Swipe19(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        if (i === Number(Val)) {
          // eslint-disable-next-line no-plusplus
          i++
        }
        if (i !== 10) {
          const R2 = String(Val) + i
          ReArr.push(R2)
        }
      }
      return ReArr
    },
    SwipeLow() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 50; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeHigh() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 50; i < 100; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeOdd() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    SwipeEven() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2 !== 1) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    SwipeDobble() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        ReArr.push(`${i}${i}`)
      }
      return ReArr
    },
    Undo() {
      this.items = []
      this.PickThreeArr = this.GenNum(1000, 3)
      this.PickTwoArr = this.GenNum(100, 2)
      this.PickOneArr = this.GenNum(10, 1)

      this.$refs['modal-undo'].hide()
      // this.$swal({
      //   title: 'ต้องการที่จะล้างโพยใช่หรือไม่?',
      //   showCancelButton: true,
      //   confirmButtonText: 'ใช่',
      //   cancelButtonText: 'ยกเลิก',
      //   customClass: {
      //     confirmButton: 'btn btn-yes',
      //     cancelButton: 'btn btn-cancel',
      //   },
      //   buttonsStyling: true,
      // }).then(result => {
      //   if (result.isConfirmed) {
      //     this.items = []
      //     this.PickThreeArr = this.GenNum(1000, 3)
      //     this.PickTwoArr = this.GenNum(100, 2)
      //     this.PickOneArr = this.GenNum(10, 1)
      //   }
      // })
    },
    CalMaxLength() {
      if (this.selectNumbet3U || this.selectNumbet3T) {
        this.CheckBetType = false
        this.maxlength = 3
      } else if (this.selectNumbet2U || this.selectNumbet2D) {
        this.CheckBetType = false
        if (
          this.TwoPushSubType.NineDoor
          || this.TwoPushSubType.RootFront
          || this.TwoPushSubType.RootBack
        ) {
          this.maxlength = 1
        } else {
          this.maxlength = 2
        }
      } else if (this.selectNumbet1U || this.selectNumbet1D) {
        this.CheckBetType = false
        this.maxlength = 1
      } else {
        this.CheckBetType = true
      }

      return this.maxlength
    },
    CalSumAmount() {
      const SumAmount = this.items.reduce(
        (acc, ele) => acc + Number(ele.price),
        0,
      )
      if (SumAmount > this.MainWallet) {
        if (this.CheckSubmit) {
          // this.SwalMes(
          //   'warning',
          //   'จำนวนเงินไม่เพียงพอ !!',
          //   'ยอดเงินของท่านไม่เพียงพอกรุณาฝากเงินก่อนค่ะ',
          // )
          this.$refs['my-warning'].show()
          this.CheckSubmit = !this.CheckSubmit
        }
      } else {
        this.CheckSubmit = true
      }
      if (this.discount_member > 0) {
        this.SumAmount = SumAmount - (this.discount_member * SumAmount) / 100
        return this.SumAmount
      }
      this.SumAmount = SumAmount
      return this.SumAmount
    },
    genDreams(item) {
      item.one_num.forEach(ele => {
        if (this.selectNumbet1U) {
          this.PushItems(ele, 'วิ่งบน', 1, 'UP', this.BaseAmount)
        }
        if (this.selectNumbet1D) {
          this.PushItems(ele, 'วิ่งล่าง', 1, 'DOWN', this.BaseAmount)
        }
      })
      item.two_num.forEach(ele => {
        if (this.selectNumbet3U2U2D) {
          this.PushItems(ele, '2ตัวบน', 2, 'UP', this.BaseAmount)
          this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.BaseAmount)
          if (this.selectNumbet3U2U2DFlip || this.selectNumbet2T) {
            const Res = this.SwitchNum2(ele)
            Res.forEach(ele2 => {
              this.PushItems(ele2, '2ตัวบน', 2, 'UP', this.BaseAmount)
              this.PushItems(ele2, '2ตัวล่าง', 2, 'DOWN', this.BaseAmount)
            })
          }
        } else {
          if (this.selectNumbet2U) {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.BaseAmount)
            if (this.selectNumbet3U2U2DFlip || this.selectNumbet2T) {
              const Res = this.SwitchNum2(ele)
              Res.forEach(ele2 => {
                this.PushItems(ele2, '2ตัวบน', 2, 'UP', this.BaseAmount)
              })
            }
          }
          if (this.selectNumbet2D) {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.BaseAmount)
            if (this.selectNumbet3U2U2DFlip || this.selectNumbet2T) {
              const Res = this.SwitchNum2(ele)
              Res.forEach(ele2 => {
                this.PushItems(ele2, '2ตัวบน', 2, 'UP', this.BaseAmount)
              })
            }
          }
        }
      })
      item.three_num.forEach(ele => {
        if (this.selectNumbet3U2U2D || this.selectNumbet3U) {
          this.PushItems(ele, '3ตัวบน', 3, 'UP', this.BaseAmount)
          if (this.selectNumbet3U2U2DFlip || this.selectNumbet3D) {
            const Switch3Arr = this.SwitchNum3(ele)
            Switch3Arr.forEach(ele2 => {
              this.PushItems(ele2, '3ตัวบน', 3, 'UP', this.BaseAmount)
            })
          }
        } else if (this.selectNumbet3T) {
          this.PushItems(ele, '3ตัวโต๊ด', 3, 'TOT', this.BaseAmount)
        }
      })
    },
    genPairs() {
      let genNum = []
      if (this.winActive === 'win3') {
        if (this.number_win.length < 3) {
          this.$refs['my-warning-3win'].show()
          // console.log('กรอกเลขอย่างน้อย 3 ตัว')
          return
        }
        genNum = this.genPairsTriplets(this.number_win, this.selectNumbet3D, this.butWinTong)
      } else if (this.winActive === 'win2') {
        if (this.number_win.length < 2) {
          this.$refs['my-warning-2win'].show()
          // console.log('กรอกเลขอย่างน้อย 2 ตัว')
          return
        }
        genNum = this.genPairsTwo(this.number_win, this.selectNumbet2T, this.butWinBle)
      } else if (this.winActive === 'win3t') {
        if (this.number_win.length < 3) {
          this.$refs['my-warning-3win'].show()
          // console.log('กรอกเลขอย่างน้อย 3 ตัว')
          return
        }
        genNum = this.genPairsTwoTriplets(this.number_win, this.selectNumbet3D, this.butWin3tTong, this.butWin3tFrontDup, this.butWin3tEndDup, this.butWin3tNoSpread)
      }
      genNum.forEach(ele => {
        if (this.winActive === 'win3' || this.winActive === 'win3t') {
          if (this.selectNumbet3U) {
            this.PushItems(ele, '3ตัวบน', 3, 'UP', this.BaseAmount)
          }
          if (this.selectNumbet3T) {
            this.PushItems(ele, '3ตัวโต๊ด', 3, 'TOT', this.BaseAmount)
          }
        }
        if (this.winActive === 'win2') {
          if (this.selectNumbet2U) {
            this.PushItems(ele, '2ตัวบน', 2, 'UP', this.BaseAmount)
          }
          if (this.selectNumbet2D) {
            this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', this.BaseAmount)
          }
        }
      })
    },
    genPairsTwo(numbers, reverse, allowDuplicates) {
      const pairs = []

      // วนลูปเพื่อจับคู่ตัวเลขทั้งหมด
      for (let i = 0; i < numbers.length; i++) {
        for (let j = i; j < numbers.length; j++) {
          // ถ้า allowDuplicates เป็น true หรือ i ไม่เท่ากับ j (ไม่มีเลขเบิ้ล)
          if (allowDuplicates || i !== j) {
            // pairs.push([numbers[i], numbers[j]])
            pairs.push(`${numbers[i]}${numbers[j]}`)

            // ถ้า reverse เป็น true และคู่ไม่ใช่เลขเบิ้ล ให้เพิ่มคู่แบบกลับ
            if (reverse && i !== j) {
              // pairs.push([numbers[j], numbers[i]])
              pairs.push(`${numbers[j]}${numbers[i]}`)
            }
          }
        }
      }

      return pairs
    },
    genPairsTriplets(
      numbers,
      reverse,
      allowTriplets,
    ) {
      const triplets = []

      // วนลูปเพื่อสร้างเลข 3 ตัวจากตัวเลขที่ไม่ซ้ำกันใน numbers
      for (let i = 0; i < numbers.length; i++) {
        for (let j = i + 1; j < numbers.length; j++) {
          for (let k = j + 1; k < numbers.length; k++) {
            // สร้างชุดเลข 3 ตัวปกติ
            // const baseTriplet = [numbers[i], numbers[j], numbers[k]]
            const baseTriplet = `${numbers[i]}${numbers[j]}${numbers[k]}`
            triplets.push(baseTriplet)

            // หากต้องการกลับเลข ให้สร้างทุกการเรียงสลับของชุดนี้
            if (reverse) {
              // triplets.push([numbers[i], numbers[k], numbers[j]])
              // triplets.push([numbers[j], numbers[i], numbers[k]])
              // triplets.push([numbers[j], numbers[k], numbers[i]])
              // triplets.push([numbers[k], numbers[i], numbers[j]])
              // triplets.push([numbers[k], numbers[j], numbers[i]])
              triplets.push(`${numbers[i]}${numbers[k]}${numbers[j]}`)
              triplets.push(`${numbers[j]}${numbers[i]}${numbers[k]}`)
              triplets.push(`${numbers[j]}${numbers[k]}${numbers[i]}`)
              triplets.push(`${numbers[k]}${numbers[i]}${numbers[j]}`)
              triplets.push(`${numbers[k]}${numbers[j]}${numbers[i]}`)
            }
          }
        }
      }

      // ถ้าอนุญาตเลขตอง ให้เพิ่มชุดเลขตอง (เช่น [a, a, a]) สำหรับตัวเลขแต่ละตัวใน numbers
      if (allowTriplets) {
        for (let i = 0; i < numbers.length; i++) {
          // triplets.push([numbers[i], numbers[i], numbers[i]])
          triplets.push(`${numbers[i]}${numbers[i]}${numbers[i]}`)
        }
      }

      return triplets
    },
    genPairsTwoTriplets(numbers, reverse, allowTriplets, noFrontDuplicate, noEndDuplicate, noSpread) {
      const result = []
      // Generate triplets
      for (let i = 0; i < numbers.length; i++) {
        for (let j = i + 1; j < numbers.length; j++) {
          if (!noFrontDuplicate) {
            // result.push([numbers[i], numbers[i], numbers[j]])
            result.push(`${numbers[i]}${numbers[i]}${numbers[j]}`)
            if (reverse) {
              // result.push([numbers[j], numbers[j], numbers[i]])
              result.push(`${numbers[j]}${numbers[j]}${numbers[i]}`)
            }
          }
          if (!noSpread) {
            // result.push([numbers[i], numbers[j], numbers[i]])
            result.push(`${numbers[i]}${numbers[j]}${numbers[i]}`)
            if (reverse) {
              // result.push([numbers[j], numbers[i], numbers[j]])
              result.push(`${numbers[j]}${numbers[i]}${numbers[j]}`)
            }
          }
          if (!noEndDuplicate) {
            // result.push([numbers[j], numbers[i], numbers[i]])
            result.push(`${numbers[j]}${numbers[i]}${numbers[i]}`)
            if (reverse) {
              // result.push([numbers[i], numbers[j], numbers[j]])
              result.push(`${numbers[i]}${numbers[j]}${numbers[j]}`)
            }
          }
        }
      }
      if (allowTriplets) {
        for (let i = 0; i < numbers.length; i++) {
          // result.push([numbers[i], numbers[i], numbers[i]])
          result.push(`${numbers[i]}${numbers[i]}${numbers[i]}`)
        }
      }
      return result
    },
    // eslint-disable-next-line consistent-return
    Commas(x) {
      if (x) {
        if (Math.round(x) !== x && x > 0) {
          const Num = Number(x)
          const y = Num.toFixed(2)
          return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    // เช็คว่าเป็นทศนิยมหรือไม่
    isDecimal(num) {
      return !Number.isInteger(num)
    },
    // แปลงทศนิยมเป็นจำนวนเต็มโดยการปัดลง
    toInteger(num) {
      return Math.floor(num)
    },
    SwalMes(Type, Title, Html) {
      this.$swal({
        icon: Type,
        title: Title,
        html: Html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    hideModalRule() {
      this.$refs['my-rule'].hide()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    hideModalAlert() {
      this.$refs['modal-alert'].hide()
    },
    hideModalLimit() {
      this.$refs['modal-limit'].hide()
    },
  },
}
</script>

<style scoped>
.btn-buy {
  height: 100px;
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    );
  color: white;
  border-radius: 20px;
}

.btn-buy span {
  font-size: 20px;
}
/*
.row {
  margin-right: -0.2rem;
  margin-left: 0.1rem;
} */

.flex-fill {
  padding: 1px;
}

.view-rules {
  display: inline-flex;
  align-items: center;
  border: 0;
  text-align: center;
  background-color: #fcc201;
  border-radius: 13px;
  font-size: 12px;
  font-weight: 700;
  padding: 5px 15px;
}

td {
  border: 1px solid #707070af;
  background: #fff;
  padding: 5px;
}

tr {
  margin: auto;
  width: 320px;
}

.form-css {
  border: none !important;
  border-radius: 10px !important;
}

.btns-bet-l {
  width: calc(100% - 110px);
}

.row-top-action {
  background-color: rgb(58, 58, 58) !important;
  /* background-image: url(/back_bg.svg) !important; */
}

.row-bottom-action {
  background-color: rgb(58, 58, 58) !important;
  /* background-image: url(/back_bg.svg) !important; */
}

@media only screen and (max-width: 768px) {
  .row-bottom-action {
    width: 100% !important;
    left: 0;
    bottom: 0;
  }
}

@media (max-width: 768px) {
  .bet-action-bottom .bet-detail {
    width: 50%;
    border-bottom: 1px solid #7f0000;
  }
}

.bet-action-bottom .bet-detail {
  min-width: 140px;
  padding: 10px 20px;
  border-right: 1px solid #7f0000;
}

.input-bottom-bet {
  width: calc(100% - 90px);
  height: 44px;
  padding: 10px 15px;
  font-size: 20px;
  font-weight: 700;
  color: #ff6700;
  border: none;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .bet-action-bottom .bet-button,
  .bet-action-bottom .bet-form {
    margin: 0 auto;
    width: 100%;
  }
}

.bet-button {
  padding: 5px 20px 15px;
  width: 360px;
  display: flex;
}

.float-left {
  float: left !important;
}

a:focus,
input {
  outline: 0;
}

.color-sub {
  color: #fff;
}

.color-white {
  color: #fff;
}

.font-page-title {
  font-size: 20px;
}

.bet-list-item.yellow {
  color: #edad0c;
}

.bet-list-item.orange {
  color: #ff6700;
}

.bet-list-item.green {
  color: #2ca468;
}

.bet-list-item.yellow.selected,
.bet-list-item.yellow.selected:hover {
  background-color: #fcc201;
  color: #fff;
}

.bet-list-item {
  float: left;
  width: 100%;
  border-radius: 15px;
  background-color: #fff;
  line-height: 20px;
  color: #818181;
  font-size: 12px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
}

.font-page-title {
  font-size: 20px;
}

.box-bet-coin-white .box-footer,
.font-sub-title {
  font-size: 12px;
}

.bet-sm-item {
  width: 100%;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  color: #818181;
  text-align: center;
  background-color: #fff;
  display: block;
}

.btn1.active {
  background: #3b5898 !important;
  color: #fff !important;
}

.button-num-cal {
  width: 100%;
  background-color: #fff;

  /* background: linear-gradient(180deg, #ffffff 0, #c4e2ff 100%); */
  border: none;
  margin: 0 0 10px;
  font-size: 30px;
  color: #000;
}

.button-num-cals {
  width: 100%;
  height: 45px;
  background-color: #f56d6d;
  /* background: linear-gradient(180deg, #ff2571 0, #202020 100%); */
  border: none;
  color: #fff;
  border-radius: 4px;
  margin: 0 0 10px;
  font-size: 15px;
}

.button-num-calss {
  width: 100%;
  background-color: #f56d6d;
  /* background: linear-gradient(180deg, #ff2571 0, #202020 100%); */
  border: none;
  height: 45px;
  border-radius: 4px;
  color: #fff;
  margin: 0 0 10px;
  font-size: 30px;
}

.button-action-cal,
.button-num-cal {
  box-shadow: 1px 1px 2px rgb(0 0 0 / 25%);
  border-radius: 4px;
  text-align: center;
  height: 45px;
}

.clicked {
  background: #3b5898 !important;
  color: #fff !important;
}

.btns,
.input-2-box .text-control {
  font-weight: 700;
  padding: 10px;
  line-height: 24px;
}

.bg-cradit {
  margin: auto;
  background-color: #fff;
  color: #000;
  width: 150px;
  font-size: 0.6rem;
  border-radius: 10px;
}

.condition-top {
  background-color: #d40000;
  border-radius: 10px 10px 0px 0px;
  padding: 5px;
  color: #fff;
}

.condition-body {
  background-color: #838383;
  border-radius: 0px 0px 10px 10px;
  color: #fff;
}

.btn-type3 {
  width: 100%;
  color: #707070;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  /* border-radius: 25px; */
  font-weight: 700;
  border: 0px;
}

.btn-type2 {
  width: 100%;
  color: #707070;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  /* border-radius: 25px; */
  border: 0px;
}

.btn-type1 {
  width: 100%;
  color: #707070;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  /* border-radius: 25px; */
  border: 0px;
}

.active,
.btnp:focus {
  border-bottom: 4px solid #0c2574;
  color: #0c2574;
}

.header-bg-content2 {
  background-color: #fbc02d;
  border-bottom: 2px solid #0c2574;
}

.bg-side {
  background-color: #111;
  border-radius: 5px;
  padding: 2px;
  color: #fff;
}

.pdd {
  margin: auto;
  max-width: 540px;
}

hr {
  margin-top: -2px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(221, 221, 221, 0.75),
    rgba(0, 0, 0, 0)
  );
}

p {
  line-height: 0.7;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.text-white {
  color: #fff !important;
}

@media screen and (max-width: 768px) {
  .btn-tanghuay {
    font-size: 12px !important;
    padding-right: 4px;
  }
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-tanghuay {
  line-height: 1;
}

.action-bar.bar-white .nav-left {
  color: rgb(0, 0, 0) !important;
  font-weight: 700;
}

.action-bar.bar-white .nav-right {
  color: rgb(0, 0, 0) !important;
}
</style>

<style lang="scss" scoped>
@import url(https://unpkg.com/@8bu/vue-otp-input@1.1.6/dist/vue-otp-input.css);
@import "@core/scss/vue/pages/page-auth.scss";

.form-control {
  padding: 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0rem;
}

.style-1 {
  .otp-input-8-field input {
    border-radius: 5;
    border: 2px solid #c50000;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.col-4,
.col-sm-6 {
  padding: 0.18rem;
  max-width: 100%;
  flex-grow: 1;
}

.square-button {
  width: 100px !important;
  height: 100px !important;
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  z-index: 9999;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #cb2025;
  box-shadow: 30px 0 0 #f8b334;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #00a096;
  box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }

  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }

  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}
</style>
